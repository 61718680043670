import {getHoursAndMinutesFromTimeString} from '@hconnect/common/components/shiftEventFormFields'
import {ParticularShift, Shift, DateRange} from '@hconnect/common/types'
import {getAllPossibleShiftsOfDay, getShift} from '@hconnect/common/utils'
import moment, {Moment} from 'moment-timezone'

import {TimeZone} from '../../types/atomic.types'

export function getShiftsForDay(
  utcDate: Moment,
  shifts: Shift[],
  timezone: TimeZone
): ParticularShift[] {
  const {list} = getAllPossibleShiftsOfDay(utcDate, shifts, timezone)
  return list
}

export function isSingleShiftRange(
  startDate: Moment,
  endDate: Moment,
  allShifts: Shift[],
  timezone: string
) {
  const startingShift = getShift(startDate, allShifts, timezone)
  const endingShift = getShift(endDate, allShifts, timezone)
  return startingShift.utcStartDate === endingShift.utcStartDate
}

/*
 *
 *
 * Previous code that can get phased out (hopefully soon) ->
 *
 *
 */

export function alignToShiftEnd(date: Date, shift: Shift, timezone: string): Date {
  const newDate = moment.tz(date, timezone)
  const [endHours, endMinutes] = getHoursAndMinutesFromTimeString(shift.end)
  if (timeIsSameOrBefore(shift.end, shift.start)) {
    // night shift!
    newDate.add(1, 'day')
  }
  return newDate
    .hours(endHours)
    .minutes(endMinutes)
    .seconds(0)
    .milliseconds(0)
    .subtract(1, 'millisecond')
    .toDate()
}

export function alignToShiftStart(date: Date, shift: Shift, timezone: string): Date {
  const newDate = moment.tz(date, timezone)
  const [startHours, startMinutes] = getHoursAndMinutesFromTimeString(shift.start)
  return newDate.hours(startHours).minutes(startMinutes).seconds(0).milliseconds(0).toDate()
}

export function getTimeRangeForShift(date: Date, shift: Shift, timezone: string): DateRange {
  const newDate = moment(date)
  // Note: assumption that whole day is covered with shifts
  // ^ this would change in future
  // Alternative: change this logic and move it to startTimeUTC and endTimeUTC
  if (timeIsSameOrBefore(shift.end, shift.start)) {
    newDate.subtract(1, 'day')
  }
  const start = alignToShiftStart(newDate.toDate(), shift, timezone)
  const end = alignToShiftEnd(newDate.toDate(), shift, timezone)
  return {startDate: moment(start), endDate: moment(end)}
}

/**
 * determines if a time if before another, where time is encoded as string in HH:mm:ss format
 */
export function timeIsSameOrBefore(a: string, b: string) {
  if (a.length !== 8 || b.length !== 8) {
    throw new Error('Invalid time input length passed')
  }
  return a.localeCompare(b, 'de') <= 0
}

/**
 * determines if 2 times are equal, where time is encoded as string in HH:mm:ss format
 */
export function isTimeEqual(utcTimeA: string, utcTimeB: string) {
  return utcTimeA.localeCompare(utcTimeB, 'de') === 0
}

/**
 * sorts the given shifts for a day based on the start time
 * @param date
 * @param shifts
 * @param timezone
 * @returns sorted array of shifts based on the start time
 */
export function sortDateShiftsOnStartTime(
  date: Moment,
  shifts: Shift[],
  timezone: string
): ParticularShift[] {
  const dateShifts = getShiftsForDay(date, shifts, timezone)
  return dateShifts.sort((a, b) => (a.start < b.start ? 0 : 1))
}

/**
 * getShiftBounds returns the start and end bounds based on shifts
 * uses first shift of the fromDate and last or current shift of the toDate
 * @param shifts
 * @param fromDate
 * @param toDate
 * @param timezone
 * @param toCurrentShift
 * @returns array with start bound and end bound values
 */
export function getShiftBounds(
  shifts: Shift[],
  fromDate: Moment,
  toDate: Moment,
  timezone: string,
  toCurrentShift?: boolean
): [start: Moment, end: Moment] {
  const fromDateShifts = sortDateShiftsOnStartTime(fromDate, shifts, timezone)
  const startDate = fromDateShifts[0].utcStartDate
  if (toCurrentShift) {
    const currentShift = getShift(toDate, shifts, timezone)
    const endDate = moment.utc(currentShift.utcEndDate)
    return [moment.tz(startDate, timezone).utc(), moment.tz(endDate, timezone).utc()]
  }
  const toDateShifts = sortDateShiftsOnStartTime(toDate, shifts, timezone)
  const endDate = toDateShifts[toDateShifts.length - 1].utcEndDate
  return [moment.tz(startDate, timezone).utc(), moment.tz(endDate, timezone).utc()]
}

export function roundUpToTheNearestMinute(date: string): Moment {
  return moment.utc(date).second() || moment.utc(date).millisecond()
    ? moment.utc(date).add(1, 'minute').startOf('minute')
    : moment.utc(date)
}
