import {AddFilter} from '@hconnect/common/components/shiftEventFormFields/AddFilter'
import {EquipmentData, StoppageKind, shifthandoverStoppagesList} from '@hconnect/common/types'
import {Done as DoneIcon} from '@mui/icons-material'
import {Button, Box} from '@mui/material'
import {TFunction} from 'i18next'
import {intersection, isEmpty} from 'lodash'
import React, {ReactNode, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {AllFilterOptions, FilterOptions} from '../../common/utils/filterParameter'
import {FilterDatePicker} from '../dateTimeAndShift/FilterDatePicker'

import {CategoryFilterDropDown} from './CategoryFilterDropDown'
import {EquipmentSearchField} from './EquipmentSearchField'
import {EventTypeFilterDropDown} from './EventTypeFilterDropDown'
import {FreeTextSearch} from './FreeTextSearch'
import {MainEquipmentSearchField} from './MainEquipmentSearchField'
import {MaintenanceNotificationFilterDropdown} from './MaintenanceNotificationFilterDropdown'
import {PmNotificationStatusFilterDropDown} from './PmNotificationStatusFilterDropDown'
import {PrioritiesFilterDropDown} from './PrioritiesFilterDropDown'
import {ProcessStageFilterDropDown} from './ProcessStageFilterDropDown'
import {RecurrenceFilterDropDown} from './RecurrenceFilterDropdown'
import {StatusFilterDropDown} from './StatusFilterDropdown'
import {StoppageCodeFilterDropDown} from './StoppageCodeFilterDropDown'
import {WorkOrderStatusFilterDropDown} from './WorkOrderStatusFilterDropDown'

interface FilterParams<T> {
  isCollapsible: boolean
  settings: FilterOptions
  options: T[]
  defaultFilters: string[]
}

type Props<T> = {
  // onChange: <K extends keyof FilterOptions>(key: K, value: FilterOptions[K]) => void
  onChange: (filterOptions: AllFilterOptions) => void
  settings: AllFilterOptions
  isCollapsible: boolean
  applyFilter: (filter: FilterOptions) => void
  options: T[]
  hideDateFilter?: boolean
  defaultFilters?: string[]
  additionalActions?: ReactNode
  showDescriptions?: (show: boolean) => void
}

const getVisibleFilters = <T extends string>({
  isCollapsible,
  settings,
  options,
  defaultFilters
}: FilterParams<T>): string[] => {
  if (isCollapsible) {
    return [...defaultFilters, ...options]
  }
  return Object.keys(settings).reduce(
    (acc, key) => {
      if (settings[key] && defaultFilters.indexOf(key) === -1) {
        acc.push(key)
      }
      return acc
    },
    [...defaultFilters]
  )
}

type FilterButtonProps<T> = {
  isCollapsible: boolean
  settings: FilterOptions
  options: T[]
  applyFilter: (filter: FilterOptions) => void
  onChange: (filterOptions: FilterOptions) => void
  additionalActions?: ReactNode
  t: TFunction
  visibleFilters: string[]
  setFilter: React.Dispatch<React.SetStateAction<string[]>>
}
const getFilterButton = <T extends string>({
  isCollapsible,
  applyFilter,
  options,
  settings,
  additionalActions,
  onChange,
  t,
  visibleFilters,
  setFilter
}: FilterButtonProps<T>) =>
  !isEmpty(options) && (
    <>
      {!isCollapsible ? (
        <>
          <AddFilter<T>
            data-test-id={'events-filter-bar-add-filter'}
            options={options}
            onChange={(value: string[] = []) => {
              return setFilter((prevFilters) => {
                const intersection = prevFilters.filter((x: string) => !value.includes(x))
                const filterName = intersection[0] as keyof FilterOptions
                onChange({...settings, [filterName]: undefined})
                return value
              })
            }}
            values={visibleFilters}
          />
          {additionalActions}
        </>
      ) : (
        <Box m={1}>
          <Button
            fullWidth
            data-test-id={'events-filter-apply-filter'}
            onClick={() => applyFilter(settings)}
            variant="contained"
            color="primary"
            startIcon={<DoneIcon />}
          >
            {t('shiftEvent.label.applyFilter')}
          </Button>
        </Box>
      )}
    </>
  )

const renderStatusFilters = (
  visibleFilters: string[],
  onChange: (filterOptions: AllFilterOptions) => void,
  settings: AllFilterOptions,
  removeFilter: (filterName: string) => void,
  isCollapsible: boolean,
  selectAll: (filterName: string, options: string[]) => void
) => {
  return (
    <>
      {visibleFilters.includes('status') && (
        <StatusFilterDropDown
          data-test-id={'events-filter-bar-status'}
          onChange={(value) => onChange({...settings, status: value})}
          value={settings.status}
          removeFilter={removeFilter}
          isCollapsible={isCollapsible}
          selectAll={selectAll}
        />
      )}
      {visibleFilters.includes('pmNotificationStatus') && (
        <PmNotificationStatusFilterDropDown
          data-test-id={'pm-notification-filter-bar-status'}
          onChange={(value) => onChange({...settings, pmNotificationStatus: value})}
          value={settings.pmNotificationStatus}
          removeFilter={removeFilter}
          isCollapsible={isCollapsible}
          selectAll={selectAll}
        />
      )}
      {visibleFilters.includes('workOrderStatus') && (
        <WorkOrderStatusFilterDropDown
          data-test-id={'work-order-filter-bar-status'}
          onChange={(value) => onChange({...settings, workOrderStatus: value})}
          value={settings.workOrderStatus}
          removeFilter={removeFilter}
          isCollapsible={isCollapsible}
          selectAll={selectAll}
        />
      )}
    </>
  )
}

export function Filter<T extends string>(props: Props<T>) {
  const {
    onChange,
    settings,
    isCollapsible,
    additionalActions,
    applyFilter,
    options,
    defaultFilters = []
  } = props
  const [visibleFilters, setFilter] = useState<string[]>(
    getVisibleFilters({isCollapsible, settings, options, defaultFilters})
  )

  const {t} = useTranslation()

  const removeFilter = (filterName: string) => {
    onChange({...settings, [filterName]: undefined})
    setFilter((prevFilters) => prevFilters.filter((pf) => pf !== filterName))
  }

  const selectAll = (filterName: string, options) => {
    onChange({...settings, [filterName]: options})
  }

  return (
    <Box
      data-test-id={'events-filter-bar-filter-options'}
      display={'flex'}
      sx={
        isCollapsible
          ? {
              flexDirection: 'column',
              paddingTop: '30px'
            }
          : {
              flexWrap: 'wrap',
              alignItems: 'center'
            }
      }
    >
      {visibleFilters.includes('timeRange') && (
        <FilterDatePicker
          onChange={(value) => {
            onChange({...settings, timeRange: value})
          }}
          value={settings.timeRange}
        />
      )}
      {visibleFilters.includes('processStage') && (
        <ProcessStageFilterDropDown
          data-test-id={'events-filter-bar-processStage'}
          onChange={(value) => onChange({...settings, processStage: value})}
          value={settings.processStage}
          removeFilter={removeFilter}
          isCollapsible={isCollapsible}
          selectAll={selectAll}
        />
      )}
      {visibleFilters.includes('equipment') && (
        <EquipmentSearchField
          data-test-id={'events-filter-bar-equipment'}
          onChange={(next: EquipmentData | undefined) => {
            onChange({...settings, equipment: next})
          }}
          value={settings.equipment}
          removeFilter={removeFilter}
        />
      )}
      {visibleFilters.includes('mainEquipment') && (
        <MainEquipmentSearchField
          data-test-id={'events-filter-bar-mainEquipment'}
          onChange={(next: EquipmentData | undefined) => {
            onChange({...settings, mainEquipment: next})
          }}
          value={settings.mainEquipment}
          removeFilter={removeFilter}
          label={t('shiftEvent.label.mainEquipment')}
        />
      )}
      {visibleFilters.includes('eventType') && (
        <EventTypeFilterDropDown
          data-test-id={'events-filter-bar-eventType'}
          onChange={(value) => onChange({...settings, eventType: value, stoppageCode: undefined})}
          value={settings.eventType}
          removeFilter={removeFilter}
          isCollapsible={isCollapsible}
          selectAll={(options) => {
            onChange({...settings, eventType: options, stoppageCode: undefined})
          }}
        />
      )}

      {visibleFilters.includes('category') && (
        <CategoryFilterDropDown
          data-test-id={'events-filter-bar-category'}
          onChange={(value) => onChange({...settings, category: value})}
          value={settings.category}
          removeFilter={removeFilter}
          isCollapsible={isCollapsible}
          selectAll={selectAll}
        />
      )}

      {visibleFilters.includes('priority') && (
        <PrioritiesFilterDropDown
          data-test-id={'events-filter-bar-priority'}
          onChange={(value) => onChange({...settings, priority: value})}
          value={settings.priority}
          removeFilter={removeFilter}
          isCollapsible={isCollapsible}
          selectAll={selectAll}
        />
      )}
      {renderStatusFilters(
        visibleFilters,
        onChange,
        settings,
        removeFilter,
        isCollapsible,
        selectAll
      )}
      {visibleFilters.includes('repetition') && (
        <RecurrenceFilterDropDown
          data-test-id={'events-filter-bar-recurrence'}
          onChange={(value) => onChange({...settings, repetition: value})}
          value={settings.repetition}
          removeFilter={removeFilter}
          isCollapsible={isCollapsible}
          selectAll={selectAll}
        />
      )}
      {visibleFilters.includes('stoppageCode') && (
        <StoppageCodeFilterDropDown
          data-test-id={'events-filter-bar-stoppageCode'}
          onChange={(value) => {
            onChange({...settings, stoppageCode: value})
          }}
          value={settings.stoppageCode}
          stoppageKinds={
            intersection(settings.eventType, shifthandoverStoppagesList) as StoppageKind[]
          }
        />
      )}
      {visibleFilters.includes('freeText') && (
        <FreeTextSearch
          data-test-id={'events-filter-bar-freeText'}
          onChange={(value) => {
            onChange({...settings, freeText: value})
          }}
          value={settings.freeText}
          showDescriptions={props.showDescriptions}
        />
      )}
      {visibleFilters.includes('maintenanceNotificationExists') && (
        <MaintenanceNotificationFilterDropdown
          data-test-id={'events-filter-bar-maintenanceNotificationExists'}
          id={'events-filter-bar-maintenanceNotificationExists'}
          onChange={(value) =>
            onChange({
              ...settings,
              maintenanceNotificationExists: value
            })
          }
          value={settings.maintenanceNotificationExists}
          removeFilter={removeFilter}
          isCollapsible={isCollapsible}
        />
      )}
      {getFilterButton({
        isCollapsible,
        applyFilter,
        options,
        settings,
        additionalActions,
        onChange,
        t,
        visibleFilters,
        setFilter
      })}
    </Box>
  )
}
