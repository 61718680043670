import {AxiosInstance} from 'axios'

import {prepareAttachmentsForMocks} from '../../__mock__/businessLogic/attachmentsUtil'
import {mockStore} from '../../__mock__/mockStoreSingleton'
import {prepareEventFormData} from '../../common/utils/formData'
import {getEventBackendEndpoint} from '../../consts'
import {EventCreate, ShiftEvent} from '../../types/shiftHandover.types'

export const createEvent = async (
  axiosInstance: AxiosInstance,
  plantId: string,
  event: EventCreate,
  scheduleRecurringTask: boolean | undefined = false
) => {
  const formData = await prepareEventFormData(event)
  const path = getEventBackendEndpoint(plantId, event.eventType, scheduleRecurringTask)

  // Unfortunately, we have no way to decode FormData inside Browser JavaScript and in our mocks
  // so when mocking is enabled, send the original DTO
  if (mockStore.isEnabled()) {
    // for mocks, we convert the attachments to base64 so we can decode this in the mock store later
    const attachmentsForMock = event.attachments?.map((attachment) => attachment.originalFile)
    event.attachments = (await prepareAttachmentsForMocks(
      attachmentsForMock?.filter(
        (attachmentForMock): attachmentForMock is File => !!attachmentForMock
      )
      // eslint-disable-next-line
    )) as any
    const response = await axiosInstance.post<ShiftEvent>(path, event)
    return response.data
  } else {
    const response = await axiosInstance.post<ShiftEvent>(path, formData)
    return response.data
  }
}
