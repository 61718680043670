import {EquipmentData, RunningTime, TaskRepetition, User} from '@hconnect/common/types'
import {appendUser, attachmentFilesToFormData, appendEquipmentData} from '@hconnect/common/utils'

import {EventCreate, EventUpdate, ParameterChangeData} from '../../types/shiftHandover.types'
import {TimeRange} from '../../types/timeAndDate.types'

type Event = EventCreate | EventUpdate

// eslint-disable-next-line complexity
export const prepareEventFormData = async (
  event: Event,
  skipKeys: string[] = []
): Promise<FormData> => {
  const formData = new FormData()
  const keys = Object.keys(event).filter((item) => skipKeys.indexOf(item) < 0)
  for (const key of keys) {
    const value = event[key]
    if (value) {
      switch (key) {
        case 'attachments':
        case 'newAttachments':
          await attachmentFilesToFormData(value, formData)
          break
        case 'attachmentsToRemove':
          value.map((url: string) => formData.append('attachmentsToRemove', url))
          break
        case 'repetitionInfo':
          appendRepetitionInfo(formData, value as TaskRepetition)
          break
        case 'equipment':
          appendEquipmentData(formData, value as EquipmentData)
          break
        case 'assignees':
          appendUsersData(formData, value as User[], 'assignees')
          break
        case 'createdBy':
          appendUser(formData, value as User, 'createdBy')
          break
        case 'parameterChange':
          appendParameterChangeData(formData, value as ParameterChangeData)
          break
        case 'mainEquipment':
          appendEquipmentData(formData, value as EquipmentData, true)
          break
        case 'schedule':
          appendSchedule(formData, value as TimeRange)
          break
        case 'pxTrendStoppage':
          appendPxTrendStoppage(formData, value as RunningTime)
          break
        case 'priority':
          if (value !== 0) {
            formData.append(key, value as string | Blob)
          }
          break
        default:
          formData.append(key, value as string | Blob)
      }
    }
  }
  return formData
}

export const appendRepetitionInfo = (formData: FormData, repetitionInfo: TaskRepetition) => {
  for (const key in repetitionInfo) {
    if (key === 'weekDays' && 'weekDays' in repetitionInfo) {
      repetitionInfo.weekDays.forEach((element) => {
        formData.append('repetitionInfo[weekDays]', element)
      })
    } else {
      formData.append(`repetitionInfo[${key}]`, repetitionInfo[key] as string | Blob)
    }
  }
}

export const appendSchedule = (formData: FormData, schedule: TimeRange) => {
  for (const key in schedule) {
    formData.append(`schedule[${key}]`, schedule[key] as string)
  }
}

export const appendPxTrendStoppage = (formData: FormData, pxTrendStoppage: RunningTime) => {
  for (const key in pxTrendStoppage) {
    formData.append(`pxTrendStoppage[${key}]`, pxTrendStoppage[key] as string)
  }
}

export const appendUsersData = (formData: FormData, users: User[], name: string) => {
  users.forEach((user, index) => {
    appendUser(formData, user, `${name}[${index}]`)
  })
}

export const appendParameterChangeData = (
  formData: FormData,
  parameterChangeData: ParameterChangeData
) => {
  for (const key in parameterChangeData) {
    if (parameterChangeData[key] || parameterChangeData[key] === false) {
      if (key === 'approvers') {
        appendUsersData(formData, parameterChangeData[key] as User[], `parameterChange[${key}]`)
      } else if (key === 'checkedBy') {
        appendUser(formData, parameterChangeData[key] as User, `parameterChange[${key}]`)
      } else {
        formData.append(`parameterChange[${key}]`, parameterChangeData[key] as string)
      }
    }
  }
}
