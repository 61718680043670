import {DateRange} from '@hconnect/common/types'
import {Box} from '@mui/material'
import {isString} from 'lodash'
import moment from 'moment-timezone'
import React, {useMemo} from 'react'

import {
  getQuickSelectFilter,
  QuickSelectSlots,
  QUICK_SELECT_SLOTS
} from '../../common/utils/filterParameter'
import {useConfig} from '../../hooks/useConfig'
import {filterSxStyles} from '../../styles/filter'

import {DatePicker} from './DatePicker'

type DatePickerProps = {
  onChange: (value: QuickSelectSlots | DateRange) => void
  value: QuickSelectSlots | DateRange | undefined
}

type DateRangeArray = [Date, Date]

export const FilterDatePicker: React.FC<DatePickerProps> = (props) => {
  const config = useConfig()
  const {shifts, timezone, utcNow, defaultTimeRange} = config

  // Default quick select option is currentShift
  const {onChange, value: selectedOption = defaultTimeRange} = props

  const state = useMemo(() => {
    let date: Date | undefined
    let quickSelectId: QuickSelectSlots | undefined
    let dateRange: DateRangeArray | undefined
    if (selectedOption && !isString(selectedOption)) {
      // Date or a date range is selected
      const {startDate, endDate}: DateRange = selectedOption
      if (startDate && endDate) {
        // In case of whole day selection, starting time is picked to calculate date
        const start = moment.tz(startDate, timezone)
        const end = moment.tz(endDate, timezone)
        const diff = moment.duration(end.diff(start)).asHours()
        if (diff >= 24) {
          dateRange = [start.toDate(), end.toDate()]
        } else {
          date = start.toDate()
        }
      }
    } else {
      // Quick select option is selected
      quickSelectId = selectedOption

      // If applied quick select filter key does not exist, fall back to last 30 days
      if (!quickSelectId || !QUICK_SELECT_SLOTS.includes(quickSelectId)) {
        quickSelectId = 'last30days'
      }

      // calculate quick select corresponding date for calender
      const [, end] = getQuickSelectFilter(quickSelectId, shifts, utcNow(), timezone)
      // subtracted 1 min to always fall in the shift duration
      date = moment.tz(end, timezone).subtract(1, 'minute').toDate()
    }

    return {
      quickSelectId,
      date,
      dateRange
    }
  }, [selectedOption, shifts, timezone, utcNow])

  const {quickSelectId, date, dateRange} = state

  return (
    <Box sx={filterSxStyles.dateFilter} data-test-id="simple-shift-picker">
      <DatePicker
        quickSelectId={quickSelectId}
        date={date}
        dateRange={dateRange}
        onChange={onChange}
      />
    </Box>
  )
}
