import {ContentBox} from '@hconnect/common/components/ContentBox'
import {DateTimeFormat} from '@hconnect/common/components/dateTime/DateTimeFormat'
import {PriorityTitle} from '@hconnect/common/components/shiftEventFormFields'
import {getEquipmentLabelDetails} from '@hconnect/common/components/shiftEventLabels'
import {Tag} from '@hconnect/common/components/Tag'
import {SIDE_CARD_MIN_HEIGHT_IN_PX} from '@hconnect/common/consts'
import CloseIcon from '@mui/icons-material/Close'
import {Box, Grid, Typography} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {EventCreator, WorkOrders} from '../../components/eventProperties/EventInfo'
import {WorkOrderStatusLabel} from '../../components/eventProperties/WorkOrderStatusLabel'
import {regularSize} from '../../consts'
import {useWorkOrder} from '../../hooks/api/workOrders/useWorkOrders'
import {useConfig} from '../../hooks/useConfig'
import {SideCardComponentProps, WorkOrder} from '../../types/workOrders.types'

export const WorksOrderSideCard: SideCardComponentProps<WorkOrder> = ({
  item: worksOrder,
  itemId: worksOrderId,
  doClose
}) => {
  const {t} = useTranslation()
  const {timezone} = useConfig()

  const {data} = useWorkOrder(worksOrderId, {
    enabled: !worksOrder && !!worksOrderId
  })
  const item = worksOrder || data

  return item ? (
    <ContentBox
      data-test-id="events-details"
      mode="max100PercentOfParentHeight"
      bodyWithPadding
      title={
        <Typography
          component="span"
          variant="h2"
          sx={{textTransform: 'none'}}
          data-test-id="event-detail-title"
          display="flex"
          alignItems="center"
        >
          {item?.title || item.id}
        </Typography>
      }
      afterTitle={
        <Box display="flex" alignItems="center">
          <Typography
            sx={{
              p: 1.75,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer'
            }}
          >
            <CloseIcon onClick={doClose} color="primary" data-test-id="events-details-close" />
          </Typography>
        </Box>
      }
      headerSx={{paddingBottom: 0}}
    >
      <EventCreator
        name={item.createdBy.name}
        created={item.createdAt}
        modifiedBy={item.updatedBy?.name}
      />
      {item.pmNotificationId && <WorkOrders notificationId={item.pmNotificationId} />}
      <Grid
        container
        sx={{
          my: 1.5
        }}
        spacing={2}
      >
        <Grid item {...regularSize} data-test-id="event-detail-priority">
          <Tag labelKey="shiftEvent.label.priority">
            {!item.priority ? (
              t('shiftEvent.action.noneOfThat')
            ) : (
              <PriorityTitle priority={item.priority} />
            )}
          </Tag>
        </Grid>
        <Grid item {...regularSize}>
          <Tag labelKey="shiftEvent.label.status" data-test-id="event-detail-status">
            {item.status && <WorkOrderStatusLabel status={item.status} />}
          </Tag>
        </Grid>
        {item.orderType && (
          <Grid item {...regularSize}>
            <Tag labelKey="shiftEvent.label.orderType" data-test-id="event-detail-orderType">
              {`${t(`sapWorkOrderTypes.${item.orderType}`, item.orderType)} (${item.orderType})`}
            </Tag>
          </Grid>
        )}
        <Grid item {...regularSize}>
          <Tag labelKey="shiftEvent.label.responsible" data-test-id="event-detail-responsible">
            {item.responsible?.name}
          </Tag>
        </Grid>
        <Grid item {...regularSize}>
          <Tag
            labelKey="shiftEvent.label.functionalLocation"
            data-test-id="event-detail-functionalLocation"
          >
            {item.functionalLocation}
          </Tag>
        </Grid>
        <Grid item xs={6}>
          <Tag labelKey="shiftEvent.label.startDate" data-test-id="event-detail-startDate">
            <DateTimeFormat date={item.startDate} timezone={timezone} />
          </Tag>
        </Grid>
        <Grid item xs={6}>
          <Tag labelKey="shiftEvent.label.endDate" data-test-id="event-detail-endDate">
            <DateTimeFormat date={item.endDate} timezone={timezone} />
          </Tag>
        </Grid>
        <Grid item xs={12}>
          <Tag data-test-id="event-detail-equipment" labelKey="shiftEvent.label.equipment">
            {getEquipmentLabelDetails(item.equipment)}
          </Tag>
        </Grid>
        <Grid item {...regularSize}>
          <Tag labelKey="shiftEvent.label.workCenter" data-test-id="event-detail-workCenter">
            {item.workCenter}
          </Tag>
        </Grid>
        <Grid item {...regularSize}>
          <Tag labelKey="shiftEvent.label.costCenter" data-test-id="event-detail-costCenter">
            {item.costCenter}
          </Tag>
        </Grid>
        <Grid item {...regularSize}>
          <Tag labelKey="shiftEvent.label.profitCenter" data-test-id="event-detail-profitCenter">
            {item.profitCenter}
          </Tag>
        </Grid>
        <Grid item {...regularSize}>
          <Tag labelKey="shiftEvent.label.activityType" data-test-id="event-detail-activityType">
            {item.activityType}
          </Tag>
        </Grid>
        <Grid item {...regularSize}>
          <Tag labelKey="shiftEvent.label.duration" data-test-id="event-detail-duration">
            {item.duration}
          </Tag>
        </Grid>
      </Grid>
    </ContentBox>
  ) : (
    <ContentBox
      data-test-id="sideCard-spinner"
      isLoading
      minHeight={SIDE_CARD_MIN_HEIGHT_IN_PX}
      mode="max100PercentOfParentHeight"
    />
  )
}
