import {useProcessStages} from '@hconnect/common/hooks/useProcessStages'
import {TFunction} from 'i18next'
import {useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import {FilterOptions} from '../../common/utils/filterParameter'
import {
  ELECTRICAL,
  ENVIRONMENT,
  HEALTH_AND_SAFETY,
  MECHANICAL,
  OTHER,
  QUALITY,
  SHIFT_SUMMARY_EVENTS_PAGE_SIZE
} from '../../consts'
import {ShiftEvent, SortBy} from '../../types/shiftHandover.types'
import {useConfig} from '../useConfig'
import {useOnPageWithoutUnmountFocus} from '../useOnPageWithoutUnmountFocus'

import {useEventsSearch} from './useEventsSearch'

export type Group = {
  id: string
  label: string
  list: ShiftEvent[]
}

type GroupMap = Map<string, Group>

const getGeneralGroupingMap = (t: TFunction) => {
  const map = new Map<string, Group>()

  map.set(HEALTH_AND_SAFETY, {
    label: t('shiftEvent.eventType.healthAndSafety'),
    id: HEALTH_AND_SAFETY,
    list: []
  })
  map.set(ENVIRONMENT, {
    label: t('shiftEvent.category.environment'),
    id: ENVIRONMENT,
    list: []
  })
  map.set(QUALITY, {
    label: t('shiftEvent.category.quality'),
    id: QUALITY,
    list: []
  })
  return map
}

const getProductionGroupingMap = (t: TFunction, processStages: Map<string, string>) => {
  const stageNames = [...processStages.values()]
  const stageKeys = [...processStages.keys()]
  const map = new Map<string, Group>()

  stageNames.forEach((label, i) => {
    const id = stageKeys[i]
    map.set(id, {
      label,
      id,
      list: []
    })
  })

  map.set(OTHER, {
    label: t('shiftEvent.category.other'),
    id: OTHER,
    list: []
  })

  return map
}

const getMaintenanceGroupingMap = (t: TFunction) => {
  const map = new Map<string, Group>()
  map.set(MECHANICAL, {
    label: t('shiftEvent.category.mechanical'),
    id: MECHANICAL,
    list: []
  })
  map.set(ELECTRICAL, {
    label: t('shiftEvent.category.electrical'),
    id: ELECTRICAL,
    list: []
  })
  return map
}

const grouping = (
  events: ShiftEvent[],
  processStages: Map<string, string>,
  t: TFunction
): {
  generalMap: GroupMap
  productionMap: GroupMap
  maintenanceMap: GroupMap
} => {
  const generalMap = getGeneralGroupingMap(t)
  const productionMap = getProductionGroupingMap(t, processStages)
  const maintenanceMap = getMaintenanceGroupingMap(t)

  events.forEach((event: ShiftEvent) => {
    if (event.eventType === HEALTH_AND_SAFETY || event.category === 'healthAndSafety') {
      generalMap.get(HEALTH_AND_SAFETY)?.list.push(event)
    } else if (event.category === QUALITY || event.eventType === QUALITY) {
      generalMap.get(QUALITY)?.list.push(event)
    } else if (event.category === ENVIRONMENT || event.eventType === ENVIRONMENT) {
      generalMap.get(ENVIRONMENT)?.list.push(event)
    } else if (event.category === MECHANICAL || event.category === ELECTRICAL) {
      maintenanceMap.get(event.category)?.list.push(event)
    } else if (event.processStage) {
      productionMap.get(event.processStage)?.list.push(event)
    } else {
      productionMap.get(OTHER)?.list.push(event)
    }
  })

  return {generalMap, productionMap, maintenanceMap}
}

export const useShiftSummaryEvents = (
  options: FilterOptions
): [
  generalGroup: GroupMap,
  productionGroup: GroupMap,
  maintenanceGroup: GroupMap,
  isLoading: boolean,
  totalItemCount: number
] => {
  const {plantId} = useConfig()
  const {refetch, ...eventQuery} = useEventsSearch(
    options,
    false,
    SortBy.Details,
    undefined,
    0,
    SHIFT_SUMMARY_EVENTS_PAGE_SIZE,
    {
      refetchOnWindowFocus: false
    }
  )
  useOnPageWithoutUnmountFocus(refetch)

  const processStages = useProcessStages(plantId)
  const {t} = useTranslation()

  const isLoading =
    eventQuery.isLoading ||
    eventQuery.isFetching ||
    processStages.isLoading ||
    processStages.isFetching

  return useMemo(() => {
    if (!eventQuery.data || !processStages.data) {
      return [new Map(), new Map(), new Map(), isLoading, 0]
    }
    const {generalMap, productionMap, maintenanceMap} = grouping(
      eventQuery.data.events,
      processStages.data,
      t
    )
    const totalItemCount = eventQuery.data.totalItemCount

    return [generalMap, productionMap, maintenanceMap, isLoading, totalItemCount]
  }, [eventQuery.data, processStages.data, t, isLoading])
}
