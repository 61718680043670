import {BarForChart, RcfaLinks} from '@hconnect/common/components/runningTimes'
import {EquipmentData, RunningTime} from '@hconnect/common/types'
import {getEventStoppageType, getRunningTimeStoppageLabel} from '@hconnect/common/utils'
import moment from 'moment-timezone'
import React, {FC, useMemo} from 'react'

import {useConfig} from '../../hooks/useConfig'
import {TimeRange} from '../../types/timeAndDate.types'

function includeGapsInRunningTimes(timeRange: TimeRange, data: RunningTime[]) {
  const runningTimesWithGaps: RunningTime[] = []
  if (data.length === 0) {
    return []
  }
  if (!moment(timeRange.startDate).isSame(moment(data[0].begin))) {
    const beginElement: RunningTime = {
      begin: timeRange.startDate,
      end: data[0].begin,
      runningTimeType: undefined,
      stoppageType: data[0].stoppageType
    }

    runningTimesWithGaps.push(beginElement)
  }

  data.forEach((rt, index) => {
    runningTimesWithGaps.push(rt)
    if (data[index + 1] && !moment(rt.end).isSame(moment(data[index + 1].begin))) {
      runningTimesWithGaps.push({
        begin: rt.end,
        end: data[index + 1].begin,
        runningTimeType: undefined,
        stoppageType: rt.stoppageType
      })
    }
  })

  if (!moment(timeRange.endDate).isSame(moment(data[data.length - 1].end))) {
    const endElement: RunningTime = {
      begin: data[data.length - 1].end,
      end: timeRange.endDate,
      runningTimeType: undefined,
      stoppageType: data[data.length - 1].stoppageType
    }

    runningTimesWithGaps.push(endElement)
  }

  return runningTimesWithGaps
}

function getBarPercentage(
  timeStart: string | undefined,
  timeEnd: string | undefined,
  totalRunningTime: number
) {
  if (!timeStart || !timeEnd || timeStart === timeEnd) {
    return '0%'
  }

  return (
    (
      (moment.utc(timeEnd).diff(moment.utc(timeStart), 'minutes') / totalRunningTime) *
      100
    ).toString() + '%'
  )
}

export interface Props {
  timeRange: TimeRange
  data: RunningTime[]
  handleStoppageClick: (rt: RunningTime, isLast: boolean) => void | Promise<void>
  handleRunningClick?: (rt: RunningTime, isLast: boolean) => void
  equipment?: EquipmentData
}

export const StackedBarChart: FC<Props> = ({
  timeRange,
  data,
  handleStoppageClick,
  handleRunningClick,
  equipment
}) => {
  const {kilnStoppageCodes, rawMillStoppageCodes, finishMillStoppageCodes, plantId} = useConfig()

  const totalRunningTime = useMemo(() => {
    return moment(timeRange.endDate).diff(moment(timeRange.startDate), 'minutes')
  }, [timeRange])

  const runningTimesWithGapsArray = useMemo(() => {
    return includeGapsInRunningTimes(timeRange, data)
  }, [timeRange, data])

  return (
    <>
      {runningTimesWithGapsArray && (
        <>
          {runningTimesWithGapsArray.map((rt, index) => (
            <BarForChart
              key={index}
              percentage={getBarPercentage(rt.begin, rt.end, totalRunningTime)}
              runningTime={rt}
              handleStoppageClick={() => void handleStoppageClick(rt, index === data.length - 1)}
              handleRunningClick={
                handleRunningClick
                  ? () => handleRunningClick(rt, index === data.length - 1)
                  : undefined
              }
              tooltip={{
                header:
                  equipment?.equipmentCategory &&
                  getRunningTimeStoppageLabel(
                    {
                      kiln: kilnStoppageCodes,
                      cementMill: finishMillStoppageCodes,
                      rawMill: rawMillStoppageCodes
                    },
                    rt,
                    getEventStoppageType(equipment)
                  ),
                footer: rt.rootCauseFailureAnalyses && (
                  <RcfaLinks
                    plantId={plantId}
                    rootCauseFailureAnalyses={rt.rootCauseFailureAnalyses}
                  />
                )
              }}
            />
          ))}
        </>
      )}
    </>
  )
}
