import {ContentBox} from '@hconnect/common/components/ContentBox'
import {SideCardModes} from '@hconnect/common/components/eventsList/types'
import {useExpandedGroups} from '@hconnect/common/hooks/useExpandGroups'
import {Priority} from '@hconnect/common/types'
import {ExpandCollapseButton} from '@hconnect/uikit/src/lib2'
import {Add as AddIcon} from '@mui/icons-material'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import {Button, Paper, Box, Typography, MenuItem, Menu, Switch} from '@mui/material'
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {FilterOptions} from '../../common/utils/filterParameter'
import {
  SHIFT_SUMMARY_EVENTS_PAGE_SIZE,
  EventListGroup,
  generalPinnedGroups,
  maintenancePinnedGroups
} from '../../consts'
import {useShiftSummaryEvents} from '../../hooks/api/useShiftSummaryEvents'
import {useFeatureFlag} from '../../hooks/useFeatureFlag'
import {usePreviousValue} from '../../hooks/usePreviousValue'
import {ShiftEvent} from '../../types/shiftHandover.types'
import {SideCard, getItemId} from '../common/SideCard'

import {GroupEventsSummary} from './GroupEventsSummary'

type Props = {
  filterOptions: FilterOptions
}

type SettingsMenuProps = {
  priority: Priority[] | undefined
  setPriority: (priority?: Priority[]) => void
}

const SettingsMenu: React.FC<SettingsMenuProps> = ({priority, setPriority}) => {
  const {t} = useTranslation()
  const [anchorEl, setAnchorEl] = useState<null | Element>(null)
  const handleOpen = useCallback((event) => {
    setAnchorEl(event.currentTarget as Element)
  }, [])

  const handleClose = useCallback(() => {
    setAnchorEl(null)
  }, [])

  return (
    <>
      <Button
        variant="text"
        data-test-id={'summary-event-settings'}
        onClick={handleOpen}
        startIcon={<SettingsOutlinedIcon color="primary" />}
        sx={{
          '& .MuiButton-startIcon': {
            marginRight: 0
          }
        }}
      />

      <Menu anchorEl={anchorEl} open={!!anchorEl} onClose={handleClose}>
        <MenuItem>
          <Box component={'span'} fontWeight={400} fontSize={16} whiteSpace="nowrap">
            {t('summary.action.prioritySwitch')}{' '}
            <Switch
              checked={!!priority}
              data-test-id="summary-priorities-filter-toggle"
              onChange={() => setPriority(priority ? undefined : [1, 2])}
              color="primary"
            />
          </Box>
        </MenuItem>
      </Menu>
    </>
  )
}

export const ShiftSummaryEvents: React.FC<Props> = (props) => {
  const {t} = useTranslation()

  const [priority, setPriority] = useState<Priority[] | undefined>()

  const filterOptions: FilterOptions = useMemo(
    () => ({...props.filterOptions, priority: priority || props.filterOptions.priority}),
    [props.filterOptions, priority]
  )

  const [generalGroup, productionGroup, maintenanceGroup, areEventsLoading, totalItemCount] =
    useShiftSummaryEvents(filterOptions)

  const groupNames = Array.from([
    ...generalGroup.keys(),
    ...productionGroup.keys(),
    ...maintenanceGroup.keys()
  ])

  const [openGroups, toggleGroup] = useExpandedGroups(groupNames, 'summary-open-groups')
  const [sideCardMode, setSideCardMode] = useState<SideCardModes | null>(null)
  const previousActiveItem = usePreviousValue(sideCardMode ? getItemId(sideCardMode) : null)

  const sideCardRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    sideCardRef?.current && sideCardRef.current.scrollIntoView({behavior: 'smooth'})
  }, [sideCardMode])

  const onItemClick = (item: ShiftEvent) => setSideCardMode({mode: 'detailsMode', itemId: item.id})

  const isAllProcessStagesSummaryEnabled = useFeatureFlag('allProcessStagesSummary')

  if (sideCardMode) {
    return (
      <Paper elevation={4} style={{overflow: 'hidden'}} ref={sideCardRef}>
        <SideCard
          {...sideCardMode}
          setMode={setSideCardMode}
          doClose={() => setSideCardMode(null)}
          data-test-id={'shift-summary-event-side-card'}
        />
      </Paper>
    )
  }

  const eventGroupsCommonProps = {
    openGroups,
    toggleGroup,
    previousActiveItem,
    onItemClick
  }

  return (
    <ContentBox
      title={
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          flexWrap="wrap"
          gap={1}
        >
          {t('summary.label.events')}
          <Box display="flex" alignItems="center" flexWrap="wrap">
            <ExpandCollapseButton
              expanded={openGroups && openGroups.length === groupNames.length}
              expandLabel={t('action.expand')}
              collapseLabel={t('action.collapse')}
              onClick={() => toggleGroup('all')}
              variant="text"
              data-test-id={'summary-events-all-toggle'}
            />
            <Button
              variant="text"
              data-test-id={'summary-event-event-create'}
              onClick={() => {
                setSideCardMode({mode: 'createMode'})
              }}
              sx={{
                '& .MuiButton-startIcon': {
                  marginRight: 0
                }
              }}
              startIcon={<AddIcon sx={{mr: 'unset'}} />}
            />
            <SettingsMenu priority={priority} setPriority={setPriority} />
          </Box>
        </Box>
      }
      bodyWithPadding
      mode={'contentDependentHeight'}
      isLoading={areEventsLoading}
      minHeight="450px"
    >
      <Typography>
        {totalItemCount > SHIFT_SUMMARY_EVENTS_PAGE_SIZE
          ? t('summary.label.totalItemCountHint', {
              totalItemCount,
              actualCount: SHIFT_SUMMARY_EVENTS_PAGE_SIZE
            })
          : undefined}
      </Typography>

      <GroupEventsSummary
        id={EventListGroup.General}
        title={t('summary.label.general')}
        events={{
          groupMap: generalGroup,
          pinnedGroups: generalPinnedGroups
        }}
        {...eventGroupsCommonProps}
      />
      <GroupEventsSummary
        id={EventListGroup.Production}
        title={t('summary.label.production')}
        events={{
          groupMap: productionGroup
        }}
        showCompleteList={isAllProcessStagesSummaryEnabled}
        {...eventGroupsCommonProps}
      />
      <GroupEventsSummary
        id={EventListGroup.Maintenance}
        title={t('summary.label.maintenance')}
        events={{
          groupMap: maintenanceGroup,
          pinnedGroups: maintenancePinnedGroups
        }}
        isLast
        {...eventGroupsCommonProps}
      />
    </ContentBox>
  )
}
