import {Material} from '@hconnect/common/types'
import {AxiosError} from 'axios'
import {useQuery, UseQueryOptions} from 'react-query'

import {useConfig} from '../useConfig'

import {useApi} from './useApi'

const QueryKey = 'materials'

export const useMaterials = (
  snapshotDateTime: string,
  options?: UseQueryOptions<Material[], AxiosError>
) => {
  const {axiosInstance} = useApi()
  const {plantId} = useConfig()

  return useQuery<Material[], AxiosError>(
    [QueryKey, snapshotDateTime, plantId],
    async () => {
      const response = await axiosInstance.get<Material[]>(
        `/plants/${plantId}/materials/history/${snapshotDateTime}`
      )
      return response.data || []
    },
    options
  )
}
