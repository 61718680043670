import {isUndefined, omit} from 'lodash'
import {useCallback, useMemo, useState} from 'react'

import {
  AllFilterOptions,
  FilterOptions,
  filterPageKeys,
  filterSettingsToUrlParams,
  getPageScopedFilters,
  Page
} from '../common/utils/filterParameter'

import {useQueryParamValue} from './useQueryParamValue'

const EVENT_FILTERS = 'eventsFilters'

type PageFiltersProps = [FilterOptions, (filterOptions: FilterOptions) => void]

export const usePageFilters = (
  page: Page,
  defaultFilters?: Partial<AllFilterOptions>
): PageFiltersProps => {
  const keys = useMemo(() => ['timeRange', ...filterPageKeys[page]], [page])
  const [eventFilters = '', setEventFilters] = useQueryParamValue(EVENT_FILTERS, keys, {
    keepInLocalStorage: true
  })

  const [filters, setFilters] = useState<FilterOptions>(
    getPageScopedFilters(eventFilters, page, defaultFilters)
  )

  const setFilterValue = useCallback(
    (filterOptions: FilterOptions) => {
      if (Object.values(filterOptions).every(isUndefined)) {
        setEventFilters('')
        setFilters({})
        return
      }
      const filtersWithoutFreeText = omit(filterOptions, 'freeText')
      setEventFilters(filterSettingsToUrlParams(filtersWithoutFreeText).toString())
      setFilters(filterOptions)
    },
    [setEventFilters]
  )

  return [filters, setFilterValue]
}
