import {
  CustomTaskRepetition,
  Iso8601,
  QuickSelectRepetition,
  TaskRepetition,
  TaskRepetitionUnit
} from '@hconnect/common/types'
import moment from 'moment-timezone'

import {IsoWeekDay, isoWeekDays, TimeZone} from '../../types/atomic.types'

export const getDefaultWeekdays = (startDateString: Iso8601, timeZone: TimeZone): IsoWeekDay[] => {
  const startDate = moment(startDateString).tz(timeZone)
  return [isoWeekDays[startDate.isoWeekday() - 1]]
}

export const quickSelectRepetition = (repetition?: TaskRepetitionUnit) => {
  const repetitionInfo: TaskRepetition = {
    repetition: repetition || 'weekly'
  }
  return repetitionInfo
}

export const fromQuickSelectToCustomTaskRepetition = (
  current: QuickSelectRepetition,
  startDate: Iso8601,
  timeZone: TimeZone
): CustomTaskRepetition => {
  if (current.repetition === 'daily') {
    return {
      repeatEvery: 1,
      repetition: 'daily',
      startDate
    }
  } // else
  return {
    repeatEvery: 1,
    repetition: 'weekly',
    startDate,
    weekDays: getDefaultWeekdays(startDate, timeZone)
  }
}

export const changeCustomTaskRepetition = (
  next: 'daily' | 'weekly',
  current: CustomTaskRepetition,
  timeZone: TimeZone
): CustomTaskRepetition => {
  const endingsOptions = current.endDate
    ? {endDate: current.endDate}
    : current.endsAfterRepetitions
      ? {endsAfterRepetitions: current.endsAfterRepetitions}
      : {}

  if (next === 'daily') {
    return {
      repeatEvery: current.repeatEvery,
      repetition: 'daily',
      startDate: current.startDate,
      ...endingsOptions
    }
  } // else
  return {
    repeatEvery: current.repeatEvery,
    repetition: 'weekly',
    startDate: current.startDate,
    weekDays: getDefaultWeekdays(current.startDate, timeZone),
    ...endingsOptions
  }
}
