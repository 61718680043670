import {ContentBox} from '@hconnect/common/components/ContentBox'
import {DateTimeFormat} from '@hconnect/common/components/dateTime/DateTimeFormat'
import {getEquipmentLabelDetails} from '@hconnect/common/components/shiftEventLabels'
import {Tag} from '@hconnect/common/components/Tag'
import {SIDE_CARD_MIN_HEIGHT_IN_PX} from '@hconnect/common/consts'
import CloseIcon from '@mui/icons-material/Close'
import {Box, Grid, Typography} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {PmNotificationStatusLabel} from '../../components/eventProperties/PmNotificationStatusLabel'
import {regularSize} from '../../consts'
import {useNotification} from '../../hooks/api/workOrders/useNotifications'
import {useConfig} from '../../hooks/useConfig'
import {Notification, SideCardComponentProps} from '../../types/workOrders.types'

export const NotificationSideCard: SideCardComponentProps<Notification> = ({
  item: notification,
  itemId: notificationId,
  doClose
}) => {
  const {t} = useTranslation()
  const {timezone} = useConfig()

  const {data} = useNotification(notificationId, {
    enabled: !notification && !!notificationId
  })
  const item = notification || data

  return item ? (
    <ContentBox
      data-test-id="events-details"
      mode="max100PercentOfParentHeight"
      bodyWithPadding
      title={
        <Typography
          component="span"
          variant="h2"
          sx={{textTransform: 'none'}}
          data-test-id="event-detail-id"
          display="flex"
          alignItems="center"
        >
          {item.id}
        </Typography>
      }
      afterTitle={
        <Box display="flex" alignItems="center">
          <Typography
            sx={{
              p: 1.75,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer'
            }}
          >
            <CloseIcon onClick={doClose} color="primary" data-test-id="events-details-close" />
          </Typography>
        </Box>
      }
      headerSx={{paddingBottom: 0}}
    >
      <Typography gutterBottom variant="caption" data-test-id="event-detail-created-at">
        {t('shiftEvent.label.wasCreatedAt')}{' '}
        <DateTimeFormat date={item.createdAt} timezone={timezone} />
      </Typography>
      <Grid
        container
        sx={{
          my: 1.5
        }}
        spacing={2}
      >
        {item.title && (
          <Grid item {...regularSize}>
            <Tag labelKey="shiftEvent.label.title" data-test-id="event-detail-title">
              {item.title}
            </Tag>
          </Grid>
        )}
        <Grid item {...regularSize}>
          <Tag labelKey="shiftEvent.label.status" data-test-id="event-detail-status">
            {item.status && <PmNotificationStatusLabel status={item.status} />}
          </Tag>
        </Grid>
        <Grid item {...regularSize}>
          <Tag labelKey="shiftEvent.label.objectNumber" data-test-id="event-detail-objectNumber">
            {item.objectNumber}
          </Tag>
        </Grid>
        {item.docNumber && (
          <Grid item {...regularSize}>
            <Tag labelKey="shiftEvent.label.docNumber" data-test-id="event-detail-docNumber">
              {item.docNumber}
            </Tag>
          </Grid>
        )}
        {item.equipment && (
          <Grid item xs={12}>
            <Tag data-test-id="event-detail-equipment" labelKey="shiftEvent.label.equipment">
              {getEquipmentLabelDetails(item.equipment)}
            </Tag>
          </Grid>
        )}
      </Grid>
    </ContentBox>
  ) : (
    <ContentBox
      data-test-id="sideCard-spinner"
      isLoading
      minHeight={SIDE_CARD_MIN_HEIGHT_IN_PX}
      mode="max100PercentOfParentHeight"
    />
  )
}
