import moment, {Moment} from 'moment'

import {SapStatusCode} from '../../types/maintenanceNotification.types'
import {ShiftEvent} from '../../types/shiftHandover.types'

export const hasMaintenanceNotificationCreationTimedOut = (
  item: Partial<ShiftEvent>,
  now: Moment
) =>
  Boolean(item.lastMaintenanceNotificationCreationStart) &&
  (!item.sapStatusCode || item.sapStatusCode === SapStatusCode.SUBMITTED) &&
  moment(item.lastMaintenanceNotificationCreationStart).add(10, 'minutes').isBefore(now)
