import {ViewMode} from '@hconnect/common/components/eventsList/types'
import {FlexPage} from '@hconnect/common/components/FlexPage'
import {EventHighlight} from '@hconnect/common/components/shiftEventFormFields/EventHighlight'
import {
  eventActionButtonSX,
  noSideCard,
  sideCardSize,
  tableWithOutSideCard,
  tableWithSideCard,
  withSideCard
} from '@hconnect/common/consts'
import {useArrowKeys, ArrowKey, scrollToEvent} from '@hconnect/common/hooks/useArrowKeys'
import {useWidth} from '@hconnect/common/hooks/useWidth'
import {modeAfterClose} from '@hconnect/common/utils/cardDetailsHelpers'
import {
  generateMarkdownOptions,
  getHighlightedTitleWithLink
} from '@hconnect/common/utils/highlightHelpers'
import {
  PaginationOptions,
  IconWithLabelButton,
  CardBox,
  IconWithTooltipButton,
  MarkdownText,
  buttonCheckedSx,
  buttonSx
} from '@hconnect/uikit/src/lib2'
import {FormControl} from '@material-ui/core'
import {Add as AddIcon, TextSnippetOutlined, Sort as SortIcon} from '@mui/icons-material'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import ListIcon from '@mui/icons-material/List'
import {Button, Grid, Box, CircularProgress, Theme, Typography} from '@mui/material'
import {TFunction} from 'i18next'
import {isEqual} from 'lodash'
import {Moment} from 'moment-timezone'
import React, {useCallback, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {
  defaultDesktop,
  desktopFilterOptions,
  DesktopFilterOptionsKeys,
  FilterOptions
} from '../../common/utils/filterParameter'
import {ShiftEventsTabToggle} from '../../components/common/ShiftEventsTabToggle'
import {
  generateCategoryColumn,
  generateCreatedAtColumn,
  generateEquipmentNumberColumn,
  generateEventTypeColum,
  generateMaintenanceNotificationColumn,
  generateProcessStageColumn,
  generateStatusColumn,
  generateTitleColumn
} from '../../components/tableHelpers'
import {useConfig} from '../../hooks/useConfig'
import {EventsTable, SortingOptions} from '../../layouts/EventsTable'
import {EventsFilterBar} from '../../layouts/FilterBar'
import {getButtonOutlineWhite, getActionContentSXWithFilter} from '../../styles/common'
import {EventsTab, ShiftEvent, SortBy, SortOrder} from '../../types/shiftHandover.types'
import {ColumnsMap} from '../../types/table.types'
import {SideCard} from '../common/SideCard'

export type Props = {
  activeFilter: FilterOptions
  events?: ShiftEvent[]
  isLoading?: boolean
  showBottomProgress?: boolean
  viewMode: ViewMode
  setViewMode: (mode: ViewMode) => void
  onFilterChange: (next: FilterOptions) => void
  paginationOptions?: PaginationOptions
  isEventDownloadLoading: boolean
  onEventsDownload: () => Promise<void>
  activeTab: EventsTab
  setActiveTab: (tab: EventsTab) => void
}

export const generateColumnsMap = (
  t: TFunction,
  timezone: string,
  plantNow: Moment,
  freeSearchText?: string
) => {
  const tableSizeColumnsMap: ColumnsMap<ShiftEvent> = {
    XS: [
      generateTitleColumn({
        translation: t,
        type: 'withEquipment',
        markOverdueTask: true,
        freeSearchText,
        plantNow
      })
    ],
    S: [
      generateEventTypeColum(t, 'small'),
      generateTitleColumn({
        translation: t,
        type: 'withEquipment',
        markOverdueTask: true,
        freeSearchText,
        plantNow
      }),
      generateStatusColumn(t, 'small')
    ],
    M: [
      generateEventTypeColum(t, 'small'),
      generateTitleColumn({translation: t, freeSearchText, plantNow}),
      generateEquipmentNumberColumn(t),
      generateCategoryColumn(t),
      generateStatusColumn(t, 'small')
    ],
    L: [
      generateEventTypeColum(t, 'small'),
      generateTitleColumn({translation: t, freeSearchText, plantNow}),
      generateEquipmentNumberColumn(t),
      generateCategoryColumn(t),
      generateCreatedAtColumn(t, timezone),
      generateStatusColumn(t, 'small')
    ],
    XL: [
      generateEventTypeColum(t, 'regular'),
      generateTitleColumn({translation: t, freeSearchText, plantNow}),
      generateEquipmentNumberColumn(t),
      generateCategoryColumn(t),
      generateProcessStageColumn(t),
      generateCreatedAtColumn(t, timezone),
      generateMaintenanceNotificationColumn(t, freeSearchText),
      generateStatusColumn(t, 'regular')
    ]
  }

  return tableSizeColumnsMap
}

const ShiftEventsTable: React.FC<{
  events?: ShiftEvent[]
  isLoading?: boolean
  viewMode: ViewMode
  setViewMode: (mode: ViewMode) => void
  showDescriptions: boolean
  paginationOptions?: PaginationOptions
  highlightedOnly?: boolean
  isSideCardShown: boolean
  freeSearchText?: string
  sortingOptions: SortingOptions
}> = ({
  events,
  highlightedOnly = false,
  setViewMode,
  viewMode,
  showDescriptions,
  paginationOptions,
  isLoading,
  isSideCardShown,
  freeSearchText,
  sortingOptions
}) => {
  const {t} = useTranslation()
  const config = useConfig()
  const columns = useMemo<ColumnsMap<ShiftEvent>>(
    () => generateColumnsMap(t, config.timezone, config.plantNow(), freeSearchText),
    [t, config, freeSearchText]
  )
  const breakPoint = useWidth()
  const shiftEvents = useMemo(
    () => (highlightedOnly ? events?.filter((ev) => ev.isHighlighted) : events),
    [highlightedOnly, events]
  )
  const tableSize = (isSideCardShown ? withSideCard : noSideCard).get(breakPoint) ?? 'S'

  const onRowClicked = useCallback(
    (clickedItem: ShiftEvent) => setViewMode({mode: 'detailsMode', itemId: clickedItem.id}),
    [setViewMode]
  )

  const selectedItemId =
    viewMode.mode === 'detailsMode' || viewMode.mode === 'editMode' ? viewMode.itemId : undefined

  const arrowKeyHandler = useCallback(
    (arrow: ArrowKey) => {
      if (
        !events ||
        !events.length ||
        viewMode.mode === 'editMode' ||
        viewMode.mode === 'createMode'
      ) {
        return
      }

      const currentPosition = !selectedItemId
        ? -1
        : events.findIndex((e) => e.id === selectedItemId)
      let newPosition = -1
      if (arrow === 'up') {
        if (currentPosition === -1) {
          newPosition = events.length - 1
        } else {
          newPosition = (currentPosition - 1 + events.length) % events.length
        }
      }
      if (arrow === 'down') {
        if (currentPosition === -1) {
          newPosition = 0
        } else {
          newPosition = (currentPosition + 1) % events.length
        }
      }
      scrollToEvent(arrow, newPosition)

      const newEventToBeSelected = events[newPosition]
      if (newEventToBeSelected) {
        onRowClicked(newEventToBeSelected)
      }
    },
    [events, selectedItemId, onRowClicked, viewMode.mode]
  )

  useArrowKeys(arrowKeyHandler)

  return (
    <EventsTable
      events={shiftEvents}
      onRowClick={onRowClicked}
      paginationOptions={paginationOptions}
      columns={columns[tableSize]}
      selectedItemId={selectedItemId}
      isLoading={isLoading}
      sortingOptions={sortingOptions}
      rowFooterTemplate={
        showDescriptions
          ? ({rowData}) => (
              <Typography
                component="div"
                sx={{
                  display: '-webkit-box',
                  WebkitLineClamp: 3,
                  WebkitBoxOrient: 'vertical',
                  overflow: 'hidden'
                }}
                data-test-id={`DataTable-desc-${rowData.id}`}
              >
                {rowData.description && (
                  <MarkdownText options={generateMarkdownOptions(freeSearchText)}>
                    {freeSearchText
                      ? getHighlightedTitleWithLink(rowData.description, freeSearchText, true)
                      : rowData.description}
                  </MarkdownText>
                )}
              </Typography>
            )
          : undefined
      }
    />
  )
}

export const EventsView: React.FC<Props> = (props) => {
  const {t} = useTranslation()
  const {viewMode, setViewMode, activeTab, setActiveTab} = props
  const breakPoint = useWidth()
  const [showDescriptions, setShowDescriptions] = useState<boolean>(false)
  const {activeFilter, onFilterChange} = props

  const sortByLatest = useMemo(
    () => activeFilter.sortBy === SortBy.LastModified,
    [activeFilter.sortBy]
  )

  const {sortBy, sortOrder} = activeFilter
  const [highlightedOnly, setHighlightedOnly] = useState<boolean | undefined>(false)

  const isSideCardShown = viewMode.mode !== 'tableOnlyMode'
  const isSmall = breakPoint === 'xs' || breakPoint === 'sm'
  const isTableShown = !isSideCardShown || !isSmall

  const [isMobileFilterOpen, openMobileFilter] = useState(false)
  const doClose = (original?: ShiftEvent, editVersion?: ShiftEvent) =>
    setViewMode(modeAfterClose<ShiftEvent>(original, editVersion))

  const freeSearchText = activeFilter.freeText

  React.useEffect(() => {
    if (freeSearchText && !showDescriptions) {
      setShowDescriptions(true)
    }
  }, [freeSearchText, showDescriptions])

  const handleSortingChange = (sortBy?: SortBy, sortOrder?: SortOrder) => {
    const next = {...activeFilter, sortBy, sortOrder}

    if (!sortBy || !sortOrder || isEqual(activeFilter, next)) {
      return
    }

    onFilterChange(next)
  }

  return (
    <FlexPage
      appName="Cockpit"
      title={
        isMobileFilterOpen ? (
          t('shiftEvent.action.filter')
        ) : (
          <ShiftEventsTabToggle activeTab={activeTab} setActiveTab={setActiveTab} />
        )
      }
      sxActionContent={getActionContentSXWithFilter(isMobileFilterOpen)}
      headerActionContent={
        <Grid container alignItems="center">
          {isTableShown && (
            <>
              <Grid item xs={12}>
                <EventsFilterBar<DesktopFilterOptionsKeys>
                  showDescriptions={setShowDescriptions}
                  activeSettings={activeFilter}
                  isCollapsible={isSmall}
                  options={desktopFilterOptions}
                  onChange={onFilterChange}
                  openMobileFilter={openMobileFilter}
                  isMobileFilterOpen={isMobileFilterOpen}
                  defaultFilters={defaultDesktop}
                  additionalActions={
                    !isSmall && (
                      <IconWithLabelButton
                        disabled={props.isEventDownloadLoading}
                        onClick={props.onEventsDownload}
                        sx={(theme: Theme) => ({
                          margin: theme.spacing(1, 1.25),
                          ...getButtonOutlineWhite(theme),
                          '&.MuiButton-root:first-of-type': {
                            margin: theme.spacing(1, 1.25)
                          }
                        })}
                        data-test-id="events-download"
                        startIcon={<FileDownloadIcon />}
                        label={t('shiftEvent.action.export')}
                      />
                    )
                  }
                />
              </Grid>
            </>
          )}
        </Grid>
      }
      useViewportHeight={!isSmall}
    >
      {!isMobileFilterOpen && (
        <Grid container flexGrow={1} overflow={'hidden'} spacing={2}>
          {isTableShown && (
            <Grid
              item
              height={'100%'}
              {...(isSideCardShown ? tableWithSideCard : tableWithOutSideCard)}
            >
              <CardBox
                sx={(theme: Theme) => ({
                  display: 'flex',
                  flexDirection: 'column',
                  height: '100%',
                  [theme.breakpoints.between('md', 'xl')]: {
                    zoom: 0.8
                  }
                })}
              >
                {isSmall ? (
                  <Box display="flex" justifyContent="space-around" mb={2}>
                    <Button
                      data-test-id="event-create"
                      onClick={() => {
                        setViewMode({mode: 'createMode'})
                      }}
                      color="secondary"
                    >
                      <AddIcon />
                    </Button>
                    <FormControl>
                      <IconWithTooltipButton
                        data-test-id="events-show-descriptions-toggle"
                        onClick={() => setShowDescriptions(!showDescriptions)}
                        icon={<ListIcon />}
                        title={t('eventsTable.action.toggleDescriptions')}
                        checked={showDescriptions}
                      />
                    </FormControl>
                    <FormControl>
                      <EventHighlight
                        data-test-id="events-highlighted-filter-toggle"
                        value={highlightedOnly}
                        onChange={() => setHighlightedOnly(!highlightedOnly)}
                        title={t('eventsTable.action.toggleHighLightedOnly')}
                      />
                    </FormControl>
                    <FormControl>
                      <IconWithTooltipButton
                        data-test-id="events-sort-by-latest-toggle"
                        onClick={() =>
                          handleSortingChange(
                            !sortByLatest ? SortBy.LastModified : SortBy.Created,
                            SortOrder.Descending
                          )
                        }
                        icon={<SortIcon />}
                        title={t('eventsTable.action.showLatestEvents')}
                        checked={sortByLatest}
                      />
                    </FormControl>
                  </Box>
                ) : (
                  <Box display={'flex'} justifyContent={'space-between'} mb={3}>
                    <Button
                      variant="text"
                      data-test-id="event-create"
                      onClick={() => {
                        setViewMode({mode: 'createMode'})
                      }}
                      startIcon={<AddIcon />}
                    >
                      {t('eventsTable.action.create')}
                    </Button>

                    <Box
                      display={'flex'}
                      flexDirection={{xs: 'column', sm: 'row'}}
                      justifyContent={'flex-end'}
                      gap={1.5}
                    >
                      <FormControl>
                        <IconWithLabelButton
                          startIcon={<TextSnippetOutlined />}
                          onClick={() => setShowDescriptions(!showDescriptions)}
                          data-test-id="events-show-descriptions-toggle"
                          label={t('eventsTable.action.toggleDescriptions')}
                          variant="outlined"
                          sx={(theme) => ({
                            ...(showDescriptions ? buttonCheckedSx(theme) : buttonSx(theme)),
                            ...eventActionButtonSX
                          })}
                        />
                      </FormControl>

                      <FormControl>
                        <EventHighlight
                          data-test-id="events-highlighted-filter-toggle"
                          value={highlightedOnly}
                          onChange={() => setHighlightedOnly(!highlightedOnly)}
                          title={t('eventsTable.action.toggleHighLightedOnly')}
                          withLabel={true}
                        />
                      </FormControl>
                      <FormControl>
                        <IconWithLabelButton
                          startIcon={<SortIcon />}
                          onClick={() =>
                            handleSortingChange(
                              !sortByLatest ? SortBy.LastModified : SortBy.Created,
                              SortOrder.Descending
                            )
                          }
                          data-test-id="events-sort-by-latest-toggle"
                          label={t('eventsTable.action.showLatestEvents')}
                          variant="outlined"
                          sx={(theme) => ({
                            ...(sortByLatest ? buttonCheckedSx(theme) : buttonSx(theme)),
                            ...eventActionButtonSX
                          })}
                        />
                      </FormControl>
                    </Box>
                  </Box>
                )}

                <ShiftEventsTable
                  events={props.events}
                  viewMode={viewMode}
                  setViewMode={setViewMode}
                  isLoading={props.isLoading}
                  showDescriptions={showDescriptions}
                  paginationOptions={props.paginationOptions}
                  isSideCardShown={isSideCardShown}
                  highlightedOnly={highlightedOnly}
                  freeSearchText={freeSearchText}
                  sortingOptions={{
                    sortBy,
                    sortOrder,
                    applySort: (sortBy, sortOrder) => handleSortingChange(sortBy, sortOrder)
                  }}
                />
                {props.showBottomProgress && (
                  <CircularProgress
                    sx={{marginX: 'auto', marginTop: 2}}
                    data-test-id="events-view-bottom-progress"
                  />
                )}
              </CardBox>
            </Grid>
          )}
          {isSideCardShown && (viewMode.mode as string) !== 'tableOnlyMode' && (
            <Grid
              item
              {...sideCardSize}
              sx={{
                height: '100%',
                overflow: 'hidden'
              }}
            >
              <SideCard
                {...viewMode}
                setMode={setViewMode}
                doClose={doClose}
                freeSearchText={freeSearchText}
              />
            </Grid>
          )}
        </Grid>
      )}

      <p data-test-id="page-event-mode-indicator" style={{display: 'none'}}>
        {/* shall be used in cypress tests to validate the mode this page is in */}
        {viewMode.mode}
      </p>
    </FlexPage>
  )
}
