import {TaskPreviewData, TaskRepetitionUnit} from '@hconnect/common/types'
import {AxiosInstance} from 'axios'
import {first} from 'lodash'
import moment, {DurationInputArg2} from 'moment-timezone'

import {isTask} from '../../common/utils/eventType'
import {createEvent} from '../../hooks/api/createEvent'
import {UseRepetitiveTasksParams} from '../../hooks/api/types'
import {updateEvent} from '../../hooks/api/updateEvent'
import {Iso8601, isoWeekDays} from '../../types/atomic.types'
import {
  ShiftEvent,
  CockpitPermission,
  EventUpdate,
  EventCreate,
  Task
} from '../../types/shiftHandover.types'
import {isCustomWeeklyRepetition} from '../../types/taskRepetitionInfo.types'
import {events as mockedEvents} from '../data/events'
import {mockStore} from '../mockStoreSingleton'

import {matchOne} from './filter'

const mapping: Record<TaskRepetitionUnit, DurationInputArg2> = {
  daily: 'days',
  weekly: 'weeks',
  monthly: 'months',
  yearly: 'years'
}

export const calculateNextDates = (repetitionInfo: TaskPreviewData, maxCount: number) => {
  if (!repetitionInfo.repetition) {
    throw new Error('repetition key is missing')
  }

  const unit = mapping[repetitionInfo.repetition]
  const repeatEvery = 'repeatEvery' in repetitionInfo ? repetitionInfo.repeatEvery ?? 1 : 1

  const startDate = moment
    .utc(repetitionInfo.startDate ?? mockStore.scenario().currentTimeUtc ?? undefined)
    .endOf('day')

  // special helper for the custom repetition
  const dateByIndex = (index: number) => {
    if (isCustomWeeklyRepetition(repetitionInfo)) {
      const occurrencesPerWeek = repetitionInfo.weekDays.length

      // how many events would have happened before the start day in the same week
      const indexOffset = repetitionInfo.weekDays.filter(
        (weekDay) => isoWeekDays.indexOf(weekDay) + 1 < startDate.isoWeekday()
      ).length

      // calculate how many full weeks this date is ahead of the start day
      const weeksToAdd = Math.round((index + indexOffset) / occurrencesPerWeek - 0.5) * repeatEvery

      // calculate the day in the week we have to jump to (monday 1 till sunday 7)
      const isoDayToJumpTo =
        isoWeekDays.indexOf(repetitionInfo.weekDays[(index + indexOffset) % occurrencesPerWeek]) + 1

      return startDate.clone().add(weeksToAdd, 'weeks').isoWeekday(isoDayToJumpTo)
    }

    // all other quick selection options
    return startDate.clone().add(index, unit)
  }

  const endDate =
    // use the given endDate
    ('endDate' in repetitionInfo && moment(repetitionInfo.endDate)) ||
    // or calculate one via endsAfterRepetitions
    ('endsAfterRepetitions' in repetitionInfo &&
      repetitionInfo.endsAfterRepetitions &&
      dateByIndex((repetitionInfo.endsAfterRepetitions - 1) * repeatEvery)) ||
    // or don't check the endDate at all
    undefined

  const result: Iso8601[] = []

  for (let index = 0; index < maxCount; index++) {
    const pointer = repeatEvery * index
    const date = dateByIndex(pointer)

    if (endDate?.isBefore(date)) {
      break
    }

    result.push(date.toISOString())
  }

  return result
}

export const scheduleCreate = async function (
  requestDto: EventCreate,
  axiosInstance: AxiosInstance
) {
  try {
    const inputType = 'id' in requestDto ? 'EventUpdate' : 'EventCreate'
    const tasksToCreate = 10

    if (!isTask(requestDto)) {
      return [420, 'the given object is not a Task']
    }

    if (!requestDto.repetitionInfo) {
      return [420, 'invalid input, repetitionInfo is not defined in the given task']
    }

    const results: ShiftEvent[] = []

    const repetitionInfo = requestDto.repetitionInfo as TaskPreviewData
    if (!('startDate' in repetitionInfo)) {
      repetitionInfo.startDate = requestDto.dueDate
    }

    const dates = calculateNextDates(repetitionInfo, tasksToCreate)
    dates.reverse()

    // cover the input task
    if (inputType === 'EventUpdate') {
      // update the existing task details
      results.push(
        await updateEvent(
          axiosInstance,
          mockStore.scenario().config.plantId,
          requestDto as EventUpdate,
          false
        )
      )
    } else {
      // add one task with the original dueDate
      dates.push(requestDto.dueDate)
    }

    // make sure we don't have duplicates
    const duplicatePos = repetitionInfo.startDate ? dates.indexOf(repetitionInfo.startDate) : -1

    if (duplicatePos !== -1) {
      dates.splice(duplicatePos, 1)
    } else {
      dates.pop()
    }

    // create the future tasks
    for (let i = 0; i < dates.length; i++) {
      const taskDto: EventCreate = {
        ...requestDto,
        dueDate: dates[i]
      }
      const singleResult = await createEvent(
        axiosInstance,
        mockStore.scenario().config.plantId,
        taskDto
      )
      results.push(singleResult)
    }

    // add task ids to schedules array
    const mockState = mockStore.scenario()
    const {schedules = []} = mockState
    const schedulesIds = results.map((result) => result.id)
    mockState.schedules = [...schedules, schedulesIds]
    mockStore.setScenarioData(mockState)

    return [200, results[0]]
  } catch (error) {
    return [500, error]
  }
}

const mockFilter = (events: ShiftEvent[], filterSettings: UseRepetitiveTasksParams) => {
  const filterCriteria = Object.keys(filterSettings)

  if (!filterCriteria.length) {
    return events
  }

  return events.filter((item) => {
    const matchList = filterCriteria.filter((key) => {
      switch (key) {
        case 'equipment':
          return (
            filterSettings.equipment?.id &&
            item.equipment?.id?.startsWith(filterSettings.equipment.id)
          )
        case 'repetition':
          return (
            filterSettings.repetition &&
            item.eventType === 'task' &&
            (item as Task).repetitionInfo &&
            filterSettings.repetition.includes(
              (item as Task).repetitionInfo?.repetition as TaskRepetitionUnit
            )
          )
        case 'processStage':
        case 'category':
          return matchOne<UseRepetitiveTasksParams>(item, filterSettings, key)

        case 'pageNumber':
        case 'itemsPerPage':
          return true

        default:
          throw new Error(`unknown filter option ${key} in taskHelper.ts`)
      }
    })

    // keep only events that match all the given criteria
    return matchList.length === filterCriteria.length
  })
}

export const scheduleGetAll = function (requestDto: UseRepetitiveTasksParams) {
  const itemsPerPage = requestDto.itemsPerPage ?? 50
  const pageNumber = requestDto.pageNumber ?? 0

  const events = mockStore.isEnabled() ? mockStore.scenario().events : mockedEvents
  const start = pageNumber * itemsPerPage
  const end = start + itemsPerPage

  const {schedules = []} = mockStore.scenario()

  const taskSchedules = mockFilter(events, requestDto)
    .filter((event) => {
      let isFirstInSeriesOrStandalone = true

      schedules.forEach((scheduleIds) => {
        if (scheduleIds && scheduleIds.includes(event.id)) {
          isFirstInSeriesOrStandalone = event.id === first(scheduleIds)
        }
      })

      return (
        event.eventType === 'task' && (event as Task).repetitionInfo && isFirstInSeriesOrStandalone
      )
    })
    .slice(start, end)

  const totalPages = Math.round(taskSchedules.length / itemsPerPage + 0.5)

  return [
    200,
    {taskSchedules, currentPage: pageNumber, totalItemCount: taskSchedules.length, totalPages}
  ]
}

export const scheduleEdit = async function (
  requestDto: EventUpdate,
  axiosInstance: AxiosInstance,
  userId
) {
  try {
    if (!isTask(requestDto)) {
      return [420, 'the given object is not a Task']
    }

    if (!requestDto.repetitionInfo) {
      return [420, 'invalid input, repetitionInfo is not defined in the given task']
    }

    // delete the tasks related to current series
    seriesTaskDelete(requestDto.id, userId)

    // create new tasks for updated series
    const taskDetails: EventCreate = {...requestDto}
    delete taskDetails['id'] // hack to use scheduleCreate function to create new tasks
    // return newly created task with updated details
    return await scheduleCreate(taskDetails, axiosInstance)
  } catch (error) {
    return [500, error]
  }
}

export const seriesTaskDelete = function (taskId, userId) {
  try {
    const mockState = mockStore.scenario()
    const event = taskId && mockState.events.find((event: ShiftEvent) => event.id === taskId)

    // check that the event exist
    if (!event || typeof taskId !== 'string') {
      return [404, 'Request can not be proceeded, task for the supplied Id could not be found']
    }

    // extract the users permission
    const permissions = mockStore.permissions
      .getPermissions(userId as string)
      .map((dfp) => dfp.permissionType as CockpitPermission)

    // check that the user has the correct permission
    if (event.eventType === 'task') {
      if (!permissions.includes('DELETE_PLANT_TASKS')) {
        return [403, 'no permission to delete a task']
      }
    } else if (!permissions.includes('DELETE_PLANT_EVENTS')) {
      return [403, 'no permission to delete an event']
    }

    // remove all the items corresponding to the series from the list
    mockState.events = mockState.events.filter((item) => {
      const isSeriesItem =
        item.created === event.created &&
        item.eventType === event.eventType &&
        item.description === event.description &&
        'repetitionInfo' in item

      // delete all its attachments
      if (isSeriesItem && mockState.eventsToImages[item.id]) {
        delete mockState.eventsToImages[item.id]
      }
      return !isSeriesItem
    })

    // delete all its stored schedules ids
    const {schedules = []} = mockState
    mockState.schedules = schedules.filter((schedule) => !schedule.includes(taskId))

    // save the changes
    mockStore.setScenarioData(mockState)

    return [200, 'Task deleted Successfully']
  } catch (error) {
    return [500, error]
  }
}
