import {AxiosError} from 'axios'
import {useMutation, UseMutationOptions, useQueryClient} from 'react-query'

import {prepareAttachmentsForMocks} from '../../__mock__/businessLogic/attachmentsUtil'
import {mockStore} from '../../__mock__/mockStoreSingleton'
import {prepareEventFormData} from '../../common/utils/formData'
import {EventCreate, EventUpdate, ShiftEvent, Task} from '../../types/shiftHandover.types'
import {useConfig} from '../useConfig'

import {createEvent} from './createEvent'
import {useApi} from './useApi'

export const useEventCreate = (
  options?: UseMutationOptions<ShiftEvent, AxiosError, EventCreate>
) => {
  const queryClient = useQueryClient()
  const {axiosInstance} = useApi()
  const {plantId} = useConfig()

  return useMutation(
    (event: EventCreate) => {
      const scheduleRecurringTask = event.eventType === 'task' && !!(event as Task).repetitionInfo
      return createEvent(axiosInstance, plantId, event, scheduleRecurringTask)
    },
    {
      ...options,
      onSuccess: async (data, variables, context) => {
        await queryClient.invalidateQueries()
        await options?.onSuccess?.(data, variables, context)
      }
    }
  )
}

/**
 * Special case for converting a simple task to recurring task
 * For editing a simple task to add recurring info, we 'POST' the data to tasks/schedules api
 * to handle the scheduling of repetitive Task independent from original create Event logic
 * the backend team decided to use a dedicated endpoint with the same DTO
 */
export const useCreateNonRecToRecEvent = (
  options?: UseMutationOptions<ShiftEvent, AxiosError, EventUpdate>
) => {
  const {axiosInstance} = useApi()
  const queryClient = useQueryClient()
  const {plantId} = useConfig()
  return useMutation(
    async (event: EventUpdate) => {
      const formData = await prepareEventFormData(event, ['attachments'])

      const path = `/shifthandover/${plantId}/tasks/schedules`
      // Unfortunately, we have no way to decode FormData inside Browser JavaScript and in our mocks
      // so when mocking is enabled, send the original DTO
      if (mockStore.isEnabled()) {
        // for mocks, we convert the attachments to base64 so we can decode this in the mock store later
        const attachmentsForMock = event.newAttachments?.map(
          (attachment) => attachment.originalFile
        )
        event.newAttachments = (await prepareAttachmentsForMocks(
          attachmentsForMock?.filter(
            (attachmentForMock): attachmentForMock is File => !!attachmentForMock
          )
          // eslint-disable-next-line
        )) as any
        const response = await axiosInstance.post<ShiftEvent>(path, event)
        return response.data
      } else {
        const response = await axiosInstance.post<ShiftEvent>(path, formData)
        return response.data
      }
    },
    {
      ...options,
      onSuccess: () => queryClient.invalidateQueries()
    }
  )
}
