import {ParticularShift} from '@hconnect/common/types/shift.types'
import type {Dates} from '@hconnect/uikit'
import {SimplePickerComponents as SPC} from '@hconnect/uikit/src/lib2'
import DateRange from '@mui/icons-material/DateRange'
import {Button, Checkbox, Popover, Box, FormControlLabel} from '@mui/material'
import React, {useCallback, useMemo, useRef, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {QuickSelectSlots} from '../../common/utils/filterParameter'
import {useConfig} from '../../hooks/useConfig'
import {getResponsivePopoverStyle} from '../../styles/common'

import {QuickSelect} from './QuickSelect'
import {
  shiftPickLabel,
  handleDateChange,
  handleDateRange,
  ShiftDropdown,
  getShiftRange
} from './ShiftDropdown'

type DateRange = [Date | null, Date | null]

interface Props {
  className?: string
  date?: Date | null
  dateRange?: DateRange
  quickSelectId?: string
  isOutsideRange?: (arg: Date) => boolean
  label?: React.ReactNode
  onChange: (value) => void
}

const formatDate = (date: Date, language: string, textFormatter?: (d: Date) => string): string =>
  textFormatter ? textFormatter(date) : date?.toLocaleDateString(language)

enum TYPES {
  QUICK_SELECT = 'quickSelect',
  DATE = 'date',
  DATE_RANGE = 'dateRange'
}

const getSelectionType = (quickSelectId, date, dateRange) => {
  if (quickSelectId) return TYPES.QUICK_SELECT
  if (date) return TYPES.DATE
  if (dateRange) return TYPES.DATE_RANGE
  throw new Error('no value selected in date picker')
}

const DatePicker: React.FC<Props> = (props) => {
  const config = useConfig()

  const {shifts, timezone, utcNow} = config

  const {quickSelectId, date, dateRange = [], isOutsideRange, onChange, className} = props
  const {t, i18n} = useTranslation()
  const {language} = i18n
  const isSingleDate = date !== undefined
  const [startDate, endDate] = dateRange
  const [selectedStartDate, setSelectedStartDate] = useState(date || startDate || null)
  const [selectedEndDate, setSelectedEndDate] = useState(endDate)
  const anchorRef = useRef(null)
  const [open, setOpen] = useState(false)
  const [showCalendar, setShowCalendar] = useState(!quickSelectId)
  const [isRangeSelect, setShowRangeSelect] = useState(!isSingleDate)
  const [selectionType, setType] = useState(getSelectionType(quickSelectId, date, dateRange))
  const noDateText = t('datePicker.chooseDate')

  const selectionText = useMemo(() => {
    if (selectionType === TYPES.QUICK_SELECT) {
      return t(`shiftEvent.quickSelection.${quickSelectId}`)
    }
    if (selectionType === TYPES.DATE || !selectedEndDate) {
      return shiftPickLabel(selectedStartDate as Date, {shifts, timezone, language})
    }
    if (selectionType === TYPES.DATE_RANGE) {
      return `${formatDate(selectedStartDate as Date, language)} - ${formatDate(
        selectedEndDate,
        language
      )}`
    }
    return noDateText
  }, [
    t,
    selectionType,
    language,
    noDateText,
    quickSelectId,
    selectedEndDate,
    selectedStartDate,
    shifts,
    timezone
  ])

  const close = useCallback(() => setOpen(false), [setOpen])

  const handleDateRangeChange = (dates: Dates<Date>) => {
    setSelectedStartDate(dates.startDate || null)
    setSelectedEndDate(dates.endDate || null)

    if (dates.endDate) {
      const finalRange = handleDateRange(dates)
      onChange(finalRange)
      setType(TYPES.DATE_RANGE)
    }
  }

  const handleSingleDateChange = (date: Date | null) => {
    if (!date) return
    const updatedDate = getShiftRange(handleDateChange(date, {shifts, timezone, utcNow}))
    setSelectedStartDate(updatedDate.startDate.toDate())
    setSelectedEndDate(updatedDate.startDate.toDate())
    onChange(updatedDate)
    setType(TYPES.DATE)
  }

  const calendarProps = {
    isOutsideRange,
    startDate: selectedStartDate,
    ...(isRangeSelect
      ? {endDate: selectedEndDate, onRangeChange: handleDateRangeChange}
      : {onDateChange: handleSingleDateChange})
  }

  return (
    <Box
      sx={{
        padding: 0,
        display: 'inline-block',
        position: 'relative',
        zIndex: 999,
        width: {
          xs: '100%',
          md: 'inherit'
        }
      }}
      className={className}
    >
      <SPC.DatePickerTextField
        buttonRef={anchorRef}
        focus={open}
        setFocus={setOpen}
        text={selectionText}
        label={t('datePicker.label')}
      />

      <Popover
        open={open}
        anchorEl={anchorRef.current}
        onClose={close}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        PaperProps={{
          sx: getResponsivePopoverStyle,
          id: 'simple-date-picker-popover-paper'
        }}
        data-test-id="simple-date-picker-popover"
      >
        <Box
          sx={{
            padding: 3,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: 356
          }}
        >
          <Box pb={1}>
            <SPC.TabBar setShowCalendar={setShowCalendar} showCalendar={showCalendar} />
          </Box>

          {showCalendar ? (
            <>
              <SPC.Calendar {...calendarProps} />
              <Box
                sx={(theme) => ({
                  color: theme.palette.text.primary,
                  width: '100%'
                })}
              >
                <FormControlLabel
                  sx={{mb: 1}}
                  control={
                    <Checkbox
                      data-test-id="range-select"
                      checked={isRangeSelect}
                      onChange={() => setShowRangeSelect((prev) => !prev)}
                    />
                  }
                  label={t('datePicker.selectRange')}
                />
                {isRangeSelect ? (
                  <SPC.PickerForm
                    applyDateRange={(value) => {
                      handleDateRangeChange(value)
                      close()
                    }}
                    isOutsideRange={isOutsideRange}
                    isSingleDate={!isRangeSelect}
                    endDate={selectedEndDate}
                    startDate={selectedStartDate}
                  />
                ) : (
                  <>
                    <ShiftDropdown
                      date={selectedStartDate}
                      onChange={(particularShift: ParticularShift) => {
                        const range = getShiftRange(particularShift)
                        onChange(range)
                        setSelectedStartDate(range.startDate.toDate())
                        setType(TYPES.DATE)
                      }}
                      label={t('datePicker.label')}
                    />
                    <Box display={'flex'} justifyContent={'center'} pt={2}>
                      <Button
                        variant={'contained'}
                        onClick={() => setOpen(false)}
                        data-test-id="single-day-apply"
                      >
                        {t('datePicker.apply')}
                      </Button>
                    </Box>
                  </>
                )}
              </Box>
            </>
          ) : (
            <QuickSelect
              onChange={(val: QuickSelectSlots) => {
                onChange(val)
                setType(TYPES.QUICK_SELECT)
                close()
              }}
              quickSelectId={quickSelectId}
            />
          )}
        </Box>
      </Popover>
    </Box>
  )
}

export {DatePicker}
