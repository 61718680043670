import {SimpleDateTimePicker} from '@hconnect/common/components/shiftEventFormFields'
import {REGULAR_SIZE} from '@hconnect/common/consts'
import {fromBrowsertimeToPlantTime, getShift} from '@hconnect/common/utils'
import {Box, Grid, Switch} from '@mui/material'
import moment from 'moment-timezone'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {useConfig} from '../../hooks/useConfig'
import {TimeRange} from '../../types/timeAndDate.types'

type ScheduleSwitchProps<T> = {
  item: Partial<T>
  onChange: (value?: TimeRange) => void
  errorText?: string
  disabled?: boolean
}

export function ScheduleSwitch<T extends {schedule?: TimeRange}>({
  item,
  onChange,
  errorText,
  disabled
}: ScheduleSwitchProps<T>) {
  const {t} = useTranslation()
  const config = useConfig()
  const currentShift = getShift(config.utcNow(), config.shifts, config.timezone)
  const startShift = moment.utc(currentShift.utcStartDate)

  const onToggle = () => {
    if (item.schedule) {
      onChange(undefined)
    } else {
      onChange({
        startDate: startShift.toISOString(),
        endDate: ''
      })
    }
  }

  return (
    <>
      <Grid item xs={12}>
        <Box ml={2} mt={1}>
          {t('shiftEvent.action.schedule')}
          <Switch
            data-test-id="schedule-event-switch"
            color="primary"
            checked={!!item.schedule}
            onChange={onToggle}
            disabled={disabled}
          />
        </Box>
      </Grid>
      {!!item.schedule && (
        <>
          <Grid item {...REGULAR_SIZE}>
            <SimpleDateTimePicker
              label={t('shiftEvent.label.startDate')}
              date={
                item.schedule.startDate
                  ? moment(item.schedule.startDate).toDate()
                  : startShift.toDate()
              }
              required={true}
              handleDateChange={(date) => {
                const browsersTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone
                const scheduleStart = fromBrowsertimeToPlantTime(
                  moment(date),
                  browsersTimezone,
                  config.timezone
                ).toISOString()
                onChange({
                  startDate: scheduleStart,
                  endDate: item.schedule?.endDate || ''
                })
              }}
              variant="filled"
              disabled={disabled}
            />
          </Grid>
          <Grid item {...REGULAR_SIZE}>
            <SimpleDateTimePicker
              label={t('shiftEvent.label.endDate')}
              date={item.schedule.endDate ? moment(item.schedule.endDate).toDate() : null}
              required={!!item.schedule}
              errorText={errorText}
              handleDateChange={(date) => {
                const browsersTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone
                const scheduleEnd = fromBrowsertimeToPlantTime(
                  moment(date),
                  browsersTimezone,
                  config.timezone
                ).toISOString()
                onChange({
                  startDate: item.schedule?.startDate || '',
                  endDate: scheduleEnd
                })
              }}
              disabled={disabled}
            />
          </Grid>
        </>
      )}
    </>
  )
}
