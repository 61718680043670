import {ParticularShift, Shift} from '@hconnect/common/types'
import {getShift} from '@hconnect/common/utils'
import moment, {Moment} from 'moment-timezone'
import React, {useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import {useConfig} from '../../hooks/useConfig'

import {SimpleShiftPicker} from './SimpleShiftPicker'

type ShiftPickerProps = {
  error?: boolean
  enableSelectRange?: boolean
  utcDate: Moment | string
  utcStartDate?: Moment | string
  label?: React.ReactNode
  onChange: (value: ParticularShift | [ParticularShift, ParticularShift]) => void
}

const generateShiftDate = (date: Moment | string, shifts: Shift[], timezone: string) => {
  const inputDate = typeof date === 'string' ? moment.utc(date) : date
  const selectedShift = getShift(inputDate, shifts, timezone)
  return moment.tz(selectedShift.utcStartDate, timezone).toDate()
}

export const ShiftPicker: React.FC<ShiftPickerProps> = ({
  utcDate,
  utcStartDate,
  label,
  error,
  ...rest
}) => {
  const {t} = useTranslation()
  const {shifts, timezone} = useConfig()
  if (!utcDate) {
    throw new Error('missing utcDate prop in ShiftPicker')
  }
  const date = useMemo(() => {
    return generateShiftDate(utcDate, shifts, timezone)
  }, [utcDate, shifts, timezone])
  const startDate = useMemo<Date | undefined>(() => {
    if (!utcStartDate) return
    return generateShiftDate(utcStartDate, shifts, timezone)
  }, [utcStartDate, shifts, timezone])
  return (
    <div data-test-id="simple-shift-picker" style={{height: '56px'}}>
      <SimpleShiftPicker
        error={error}
        date={startDate || date}
        dateRange={startDate && date ? [startDate, date] : undefined}
        noAutoClose={true}
        label={label || t('shiftEvent.label.shift')}
        {...rest}
      />
    </div>
  )
}
