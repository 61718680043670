import {ConfigContext} from '@hconnect/common/hooks/ConfigContext'
import {Config} from '@hconnect/common/types'
import {AxiosError, AxiosInstance} from 'axios'
import React, {createContext, useContext} from 'react'
import {useTranslation} from 'react-i18next'
import {UseQueryOptions, useQuery} from 'react-query'

import {getConfig} from '../common/services'
import {FeatureFlagKey} from '../types/shiftHandover.types'

import {useGlobalContext} from './useGlobalContext'

const Context = createContext<ConfigContext<FeatureFlagKey> | undefined>(undefined)

export const useConfigService = (
  axiosInstance: AxiosInstance,
  plantId: string,
  options?: UseQueryOptions<Config<FeatureFlagKey> & {serverTimestamp: string}, AxiosError>
) => {
  const {
    i18n: {language}
  } = useTranslation()

  return useQuery<Config<FeatureFlagKey> & {serverTimestamp: string}, AxiosError>(
    // refresh config on language change
    [language, plantId],
    async () => {
      return await getConfig(axiosInstance, plantId, language)
    },
    {
      ...options,
      // hook specific options
      refetchOnMount: false,
      refetchOnWindowFocus: false
    }
  )
}

export const ConfigProvider = ({children}: {children: React.ReactNode}) => {
  const {config} = useGlobalContext()
  const {serverTimestamp} = config

  const configContext = React.useMemo(
    () => new ConfigContext(config, serverTimestamp),
    [config, serverTimestamp]
  )

  return <Context.Provider value={configContext}>{children}</Context.Provider>
}

export const useConfig = () => {
  const ctx = useContext(Context)
  if (ctx === undefined) {
    throw new Error('useConfig used outside of AppConfigProvider or app-config not available')
  } else {
    return ctx
  }
}
