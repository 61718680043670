import {SideCardModes, ViewMode} from '@hconnect/common/components/eventsList/types'
import {SetURLSearchParams, useSearchParams} from 'react-router-dom'

function urlParamsToViewMode(params: URLSearchParams): ViewMode {
  const eventId = params.has('eventId') ? params.get('eventId') : undefined
  const mode = params.has('mode') ? params.get('mode') : undefined

  switch (mode) {
    case undefined:
      if (eventId) return {mode: 'detailsMode', itemId: eventId} as ViewMode
      else return {mode: 'tableOnlyMode'} as ViewMode

    case 'editMode':
    case 'detailsMode':
      if (!eventId) throw new Error('Event Id must be given with mode')
      return {mode, itemId: eventId}
    case 'createMode':
      return {mode}
    default:
      throw new Error('Unknown mode given in URL')
  }
}

export function viewModeToUrlParams(viewMode: ViewMode | SideCardModes) {
  const params = new URLSearchParams()

  switch (viewMode.mode) {
    case 'tableOnlyMode':
      return params
    case 'editMode':
    case 'detailsMode':
      params.append('mode', viewMode.mode)
      params.append('eventId', viewMode.itemId.toString())
      return params
    case 'createMode':
      params.append('mode', 'createMode')
      return params
    default:
      throw new Error('Unknown mode')
  }
}

export const useQueryParameterForViewMode = (): ViewMode => {
  const [params]: [URLSearchParams, SetURLSearchParams] = useSearchParams()
  return urlParamsToViewMode(params)
}
