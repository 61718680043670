import {FilterTimeRange} from '@hconnect/common/types'
import {AxiosError} from 'axios'
import {omit} from 'lodash'
import {useQuery, UseQueryOptions} from 'react-query'

import {PmNotificationFilterOptions} from '../../../common/utils/filterParameter'
import {
  Notification,
  NotificationPagedContainer,
  WorkOrdersPayload
} from '../../../types/workOrders.types'
import {preparePayload} from '../../../utils'
import {useConfig} from '../../useConfig'
import {useApi} from '../useApi'
import {generateTimeRange} from '../useEventsSearch'

export const NotificationsQueryKey = 'work-orders-notifications'
export const NotificationQueryKey = 'work-orders-notification'

export const useNotifications = (
  {
    input,
    normalizeForDatePicker = false,
    offset,
    limit
  }: WorkOrdersPayload<PmNotificationFilterOptions>,
  options?: UseQueryOptions<NotificationPagedContainer, AxiosError>
) => {
  const {axiosInstance} = useApi()
  const {utcNow, shifts, timezone, plantId} = useConfig()
  const path = `/work-orders/${plantId}/notifications`

  return useQuery<NotificationPagedContainer, AxiosError>(
    [NotificationsQueryKey, path, timezone, shifts, input, normalizeForDatePicker, offset, limit],
    async () => {
      const timeRange: FilterTimeRange = generateTimeRange(
        input,
        normalizeForDatePicker,
        timezone,
        shifts,
        utcNow
      )
      const filters = {
        ...omit(input, [
          'timeRange',
          'pmNotificationStatus',
          'sortNotificationsBy',
          'notifSortDir'
        ]),
        statuses: input.pmNotificationStatus,
        sortBy: input.sortNotificationsBy,
        sortingDirection: input.notifSortDir
      }
      const response = await axiosInstance.get<NotificationPagedContainer>(path, {
        params: {
          from: timeRange.startDate,
          to: timeRange.endDate,
          offset,
          limit,
          ...preparePayload<PmNotificationFilterOptions>(filters)
        }
      })
      return response.data
    },
    options
  )
}

export const useNotification = (
  notificationId?: string,
  options?: UseQueryOptions<Notification | undefined, AxiosError>
) => {
  const {axiosInstance} = useApi()
  const {plantId} = useConfig()
  const path = `/work-orders/${plantId}/notifications/${notificationId}`

  return useQuery<Notification | undefined, AxiosError>(
    [NotificationQueryKey, path],
    async () => {
      if (!notificationId) return undefined
      const response = await axiosInstance.get<Notification>(path)
      return response.data
    },
    options
  )
}
