import {ViewMode} from '@hconnect/common/components/eventsList/types'
import {FlexPage} from '@hconnect/common/components/FlexPage'
import {sideCardSize, tableWithOutSideCard, tableWithSideCard} from '@hconnect/common/consts'
import {useNotification} from '@hconnect/uikit'
import {CardBox, Column} from '@hconnect/uikit/src/lib2'
import {Add as AddIcon} from '@mui/icons-material'
import {Box, Button, Grid, Theme, useMediaQuery} from '@mui/material'
import {TFunction} from 'i18next'
import React, {FC, useCallback, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {EventProcessStageTitle} from '../../components/common/EventProcessStageTitle'
import {DocumentCreate} from '../../components/documents/DocumentCreate'
import {DocumentDetails} from '../../components/documents/DocumentDetails'
import {DocumentEdit} from '../../components/documents/DocumentEdit'
import {DocumentLink} from '../../components/documents/DocumentLink'
import {DocumentStatus} from '../../components/documents/DocumentStatus'
import {useConfig} from '../../hooks/useConfig'
import {useInstructionsPageLastVisited} from '../../hooks/useInstructionsPageLastVisited'
import {InstructionVM} from '../../types/documents.types'

import {DocumentDelete} from './DocumentDelete'
import {DocumentsTable} from './DocumentsTable'

export const generateColumnsMap = (t: TFunction, timezone: string, isMobile?: boolean) => {
  const commonConfig: Column<InstructionVM>[] = [
    {
      key: 'name',
      label: t('documents.label.documentLink'),
      sortable: true,
      customTemplate: (rowData) => <DocumentLink document={rowData} />
    },
    {
      key: 'status',
      label: t('documents.label.status'),
      sortable: true,
      customTemplate: (rowData) => <DocumentStatus document={rowData} />
    }
  ]

  if (!isMobile) {
    commonConfig.splice(1, 0, {
      key: 'processStage',
      label: t('documents.label.processStage'),
      sortable: true,
      customTemplate: ({processStage}) => <EventProcessStageTitle stage={processStage} />
    })
  }

  return commonConfig
}

type DocumentsViewProps = {
  isLoading: boolean
  viewMode: ViewMode
  setViewMode: (mode: ViewMode) => void
  documents?: InstructionVM[]
}

export const DocumentsView: FC<DocumentsViewProps> = ({
  isLoading,
  documents,
  viewMode,
  setViewMode
}) => {
  const {t} = useTranslation()
  const {timezone} = useConfig()
  const {notify} = useNotification()
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))

  const [showDeleteDialog, setDeleteDialog] = useState<boolean>(false)

  const {setLastVisited} = useInstructionsPageLastVisited()

  const columns = useMemo<Column<InstructionVM>[]>(
    () => generateColumnsMap(t, timezone, isMobile),
    [t, timezone, isMobile]
  )

  const onRowClick = useCallback(
    ({id}: InstructionVM) => setViewMode({mode: 'detailsMode', itemId: id}),
    [setViewMode]
  )

  const onDeleteSuccess = useCallback(() => {
    setViewMode({mode: 'tableOnlyMode'})
    setDeleteDialog(false)
    notify('success', t('documents.action.deleteSuccess'))
  }, [notify, setViewMode, t])

  const isSideCardShown = viewMode.mode !== 'tableOnlyMode'
  const isTableShown = !isSideCardShown || !isMobile
  const selectedDocument =
    viewMode.mode === 'detailsMode' || viewMode.mode === 'editMode'
      ? (documents || []).find((document) => document.id === viewMode.itemId)
      : undefined

  const closeSideCard = () => {
    if (viewMode.mode === 'editMode') {
      setViewMode({mode: 'detailsMode', itemId: viewMode.itemId})
    } else {
      setViewMode({mode: 'tableOnlyMode'})
    }
  }

  const onSuccessSubmit = (itemId: string) => {
    setViewMode({mode: 'detailsMode', itemId})
    notify('success', t('documents.label.createSuccess'))
    setLastVisited()
  }

  const onEdit = (id: string) => {
    setViewMode({mode: 'editMode', itemId: id})
    setLastVisited()
  }

  const onSuccessEdit = () => {
    closeSideCard()
    notify('success', t('documents.label.editSuccess'))
    setLastVisited()
  }
  const getCardContent = () => {
    if (viewMode.mode === 'detailsMode' && selectedDocument) {
      return (
        <DocumentDetails
          document={selectedDocument}
          onClose={closeSideCard}
          onEdit={onEdit}
          onDelete={() => setDeleteDialog(true)}
        />
      )
    }

    if (viewMode.mode === 'createMode') {
      return <DocumentCreate doClose={closeSideCard} onSuccessSubmit={onSuccessSubmit} />
    }

    if (viewMode.mode === 'editMode' && selectedDocument) {
      return (
        <DocumentEdit
          document={selectedDocument}
          doClose={closeSideCard}
          onSuccessSubmit={onSuccessEdit}
        />
      )
    }
  }

  return (
    <FlexPage title={t('documents.pageName')} appName="Cockpit">
      <Grid container flexGrow={1} overflow={'hidden'} spacing={2}>
        {isTableShown && (
          <Grid
            item
            height={'100%'}
            {...(isSideCardShown ? tableWithSideCard : tableWithOutSideCard)}
          >
            <CardBox
              sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%'
              }}
            >
              <Box display="flex" justifyContent="flex-end" mb={1}>
                <Button
                  variant="text"
                  data-test-id="document-create"
                  onClick={() => {
                    setViewMode({mode: 'createMode'})
                  }}
                  startIcon={<AddIcon />}
                >
                  {t('documents.action.create')}
                </Button>
              </Box>
              <DocumentsTable
                documents={documents}
                isLoading={isLoading}
                columns={columns}
                onRowClick={onRowClick}
                selectedDocumentId={selectedDocument?.id}
              />
            </CardBox>
          </Grid>
        )}

        {isSideCardShown && (viewMode.mode as string) !== 'tableOnlyMode' && (
          <Grid
            item
            {...sideCardSize}
            sx={{
              height: '100%',
              overflow: 'hidden'
            }}
          >
            {getCardContent()}
          </Grid>
        )}
      </Grid>

      {!!(showDeleteDialog && selectedDocument) && (
        <DocumentDelete
          onSuccess={onDeleteSuccess}
          setDialogOpen={setDeleteDialog}
          instruction={selectedDocument}
          showDialog={true}
        />
      )}

      <p data-test-id="page-document-mode-indicator" style={{display: 'none'}}>
        {/* shall be used in cypress tests to validate the mode this page is in */}
        {viewMode.mode}
      </p>
    </FlexPage>
  )
}
