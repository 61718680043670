import {ContentBox, ContentMode} from '@hconnect/common/components/ContentBox'
import {LinearProgress, Theme, Box} from '@mui/material'
import {SystemStyleObject} from '@mui/system/styleFunctionSx/styleFunctionSx'
import moment from 'moment'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {NewComment} from '../../components/common/CommentForm'
import {CommentsList} from '../../components/common/CommentList'
import {useDeleteComment, useEditComment} from '../../hooks/api/useCommentsAction'
import {useCreateShiftComment, useFetchShiftComments} from '../../hooks/api/useShiftComments'
import {useHasPermission} from '../../hooks/usePermission'
import {TimeRange} from '../../types/timeAndDate.types'

type CommentsProps = {
  timeRange: TimeRange
  contentMode: ContentMode
  isSingleShift: boolean
  bodySx?: SystemStyleObject<Theme>
  headerSx?: SystemStyleObject<Theme>
}

export const ShiftComments: React.FC<CommentsProps> = ({
  headerSx,
  bodySx,
  timeRange,
  contentMode,
  isSingleShift
}) => {
  const hasPermission = useHasPermission()
  const {t} = useTranslation()
  const {endDate} = timeRange
  const now = moment.utc()
  const {
    data: comments,
    isLoading: commentsLoading,
    isFetching: commentsFetching
  } = useFetchShiftComments(timeRange)

  // create comment either for current time or clamp it to shift end time to ensure it stays within the shift
  const submittedForTimestamp = now.isAfter(endDate) ? endDate : now.toISOString()

  const createComment = useCreateShiftComment()
  const deleteCommentAction = useDeleteComment(true)
  const editCommentAction = useEditComment(true)

  const showLinearProgress = commentsLoading || commentsFetching

  return (
    <ContentBox
      title={`${t('shiftEvent.label.comments')}${comments ? ` (${comments.length})` : ''}`}
      titleTestId="shift-events-heading"
      bodyWithPadding
      headerSx={headerSx}
      bodySx={bodySx}
      mode={contentMode}
      data-test-id="shift-comments-container"
    >
      <Box data-test-id="shift-comments" pt={1}>
        {showLinearProgress && <LinearProgress data-test-id="events-comments-loading" />}
        {comments && (
          <div>
            {hasPermission('ADD_PLANT_EVENT_COMMENTS') && isSingleShift && (
              <NewComment
                submittedForTimestamp={submittedForTimestamp}
                createCommentAction={createComment}
              />
            )}
            {hasPermission('VIEW_PLANT_EVENT_COMMENTS') && (
              <CommentsList
                comments={comments}
                editCommentAction={editCommentAction}
                deleteCommentAction={deleteCommentAction}
              />
            )}
          </div>
        )}
      </Box>
    </ContentBox>
  )
}
