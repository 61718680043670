import {CommonDropDown} from '@hconnect/common/components/shiftEventFormFields'
import {ParticularShift, Shift} from '@hconnect/common/types'
import {getShift} from '@hconnect/common/utils'
import {dateFormatter, Dates} from '@hconnect/uikit'
import {Box} from '@mui/material'
import moment, {isMoment, Moment} from 'moment-timezone'
import React, {useMemo} from 'react'

import {getShiftsForDay} from '../../common/utils/shift'
import {useConfig} from '../../hooks/useConfig'

export interface ShiftDropdownProps {
  date: Date | null
  className?: string
  label?: React.ReactNode
  onChange: (particularShift: ParticularShift) => void
  keyName?: string
}

type DateChangeOptions = {
  utcNow: () => Moment
  timezone: string
  shifts: Shift[]
}

type ShiftPickOptions = {
  language: string
  timezone: string
  shifts: Shift[]
}

export const handleDateChange = (localNext: Date, options: DateChangeOptions) => {
  // next will have the time set to 00:00:00 of the picked day !! IN MACHINE TIME!!
  // so we align it first to 00:00:00 of the picked day in plant time
  // and then with the current time,
  // to have a predictable behavior while switching between different days
  const {utcNow, timezone, shifts} = options
  const plantNow = utcNow()

  const plantMidnight = moment(localNext).tz(timezone, true)
  const syncedPlantNext = plantMidnight.clone().set({
    hours: plantNow.get('hours'),
    minutes: plantNow.get('minutes'),
    seconds: plantNow.get('seconds')
  })

  let particularShift = getShift(syncedPlantNext.utc(), shifts, timezone)

  const shiftStart = moment.tz(particularShift.utcStartDate, timezone)
  if (!shiftStart.isSame(syncedPlantNext, 'day')) {
    // special case with day roll over
    particularShift = getShift(syncedPlantNext.add(1, 'day'), shifts, timezone)
  }

  return particularShift
}

export const handleDateRange = (dates: Dates<Date>) => {
  return {startDate: moment.utc(dates.startDate), endDate: moment.utc(dates.endDate)}
}

export const shiftPickLabel = (date: Date, options: ShiftPickOptions) => {
  const {shifts, timezone, language} = options
  const utcDate = moment.utc(date)
  const selectedShift = getShift(utcDate, shifts, timezone)
  const localStartTime = moment.tz(selectedShift.utcStartDate, timezone)
  const dateLabel = dateFormatter(localStartTime, language)
  return `${dateLabel} - ${selectedShift?.name}`
}

export const getShiftRange = (pickedShift: ParticularShift) => {
  return {
    startDate: moment.utc(pickedShift.utcStartDate),
    endDate: moment.utc(pickedShift.utcEndDate)
  }
}

export const ShiftDropdown: React.FC<ShiftDropdownProps> = (props) => {
  const config = useConfig()
  const {shifts, timezone} = config
  const {date: inputDate, keyName} = props

  if (!inputDate) {
    throw new Error('missing utcDate prop in ShiftPicker')
  }

  const state = useMemo(() => {
    const utcDate: Moment = isMoment(inputDate) ? inputDate : moment.utc(inputDate)

    const shiftsForThatDay = getShiftsForDay(utcDate, shifts, timezone)
    const selectedShift = getShift(utcDate, shifts, timezone)
    const localStartTime = moment.tz(selectedShift.utcStartDate, timezone)

    if (!shiftsForThatDay.find((shift) => shift.utcStartDate === selectedShift?.utcStartDate)) {
      // just a safety net as it should not happen but if it does it will result in some wired error in the CommonDropDown
      throw new Error('selectedShift is not in the list of shiftsForThatDay')
    }

    const shiftPickLabel = (shiftId: string) => {
      const shift = state.shiftsForThatDay[state.selectOptions.indexOf(shiftId)]
      return `${shift.name} ${shift.start.substring(0, 5)}-${shift.end.substring(0, 5)}`
    }

    return {
      utcDate,
      localStartTime,
      shiftsForThatDay,
      selectedShift,
      selectOptions: shiftsForThatDay.map((s) => s.utcStartDate),
      shiftPickLabel
    }
  }, [inputDate, shifts, timezone])
  return (
    <Box>
      <CommonDropDown
        keyName={keyName || 'shift'}
        data-test-id="shift-picker"
        fullWidth={true}
        onChange={(utcStartDate) => {
          const particularShift = state.shiftsForThatDay.find(
            (shift) => shift.utcStartDate === utcStartDate
          )
          particularShift && props.onChange(particularShift)
        }}
        required
        options={state.selectOptions}
        customTemplate={state.shiftPickLabel}
        value={state.selectedShift.utcStartDate}
      />
    </Box>
  )
}
