import {useMemo} from 'react'
import {SetURLSearchParams, useSearchParams} from 'react-router-dom'

import {FilterOptions, urlToFilterSettings} from '../common/utils/filterParameter'
import {HLog} from '../common/utils/logging'

export const useQueryParameterForFilterOptions = (): FilterOptions => {
  const [params]: [URLSearchParams, SetURLSearchParams] = useSearchParams()
  const warning = (text: string) => HLog('cockpit').warning(text)
  return useMemo(() => urlToFilterSettings(params, warning), [params])
}
