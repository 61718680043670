import {DateTimeFormat} from '@hconnect/common/components/dateTime/DateTimeFormat'
import {EventStatusLabel} from '@hconnect/common/components/eventProperties/EventStatusLabel'
import {OverdueLabel} from '@hconnect/common/components/eventProperties/OverdueLabel'
import {EquipmentLabel, EventTypeLabel} from '@hconnect/common/components/shiftEventLabels'
import {FIX_COLUMN_WIDTH} from '@hconnect/common/consts'
import {getHighlightedTitle} from '@hconnect/common/utils/highlightHelpers'
import type {Column} from '@hconnect/uikit/src/lib2'
import {mergeSx} from '@hconnect/uikit/src/lib2'
import {SxProps, Theme, Typography} from '@mui/material'
import {TFunction} from 'i18next'
import {isEmpty} from 'lodash'
import {Moment} from 'moment-timezone'
import React from 'react'

import {isStoppage, isTask} from '../common/utils/eventType'
import {EventProcessStageTitle} from '../components/common/EventProcessStageTitle'
import {ShiftEvent, Task} from '../types/shiftHandover.types'
import {Equipment} from '../types/workOrders.types'

type SecondaryProps = {
  sx?: SxProps<Theme>
  children: React.ReactNode | string
}

export const Secondary: React.FC<SecondaryProps> = ({children, sx}) => {
  return (
    <Typography variant="body1" sx={mergeSx({fontWeight: 500}, sx)}>
      {children}
    </Typography>
  )
}

type LabelProps = {
  sx?: SxProps<Theme>
  children: React.ReactNode | string
}

export const Label = ({children, sx}: LabelProps) => {
  return <Typography sx={mergeSx({fontSize: 12, lineHeight: '16px'}, sx)}>{children}</Typography>
}

type GenerateTitleArguments = {
  translation: TFunction
  type?: 'withEquipment' | 'regular'
  markOverdueTask?: boolean
  freeSearchText?: string
  plantNow: Moment
}

export enum ColumnKeys {
  EventType = 'eventType',
  Title = 'title',
  EquipmentNumber = 'equipmentNumber',
  Category = 'category',
  ProcessStage = 'processStage',
  Created = 'created',
  MaintenanceNotification = 'maintenanceNotification',
  Status = 'status'
}

export const generateTitleColumn = ({
  translation: t,
  type,
  markOverdueTask = true,
  freeSearchText,
  plantNow
}: GenerateTitleArguments): Column<ShiftEvent> => {
  const title =
    type === 'withEquipment'
      ? `${t('shiftEvent.label.equipment')}: ${t('shiftEvent.label.title')}`
      : t('shiftEvent.label.title')

  return {
    key: ColumnKeys.Title,
    label: <Label>{title}</Label>,
    flex: 4,
    sortable: true,
    customTemplate: (item: ShiftEvent) => (
      <Typography
        variant="h4"
        color="textPrimary"
        sx={{fontSize: 16, fontWeight: 500, wordBreak: 'break-word'}}
      >
        {type === 'withEquipment' && item.equipment ? (
          <>
            <EquipmentLabel withId equipment={item.equipment} />: {item.title}
          </>
        ) : (
          <>
            {freeSearchText ? getHighlightedTitle(item.title, freeSearchText) : item.title}{' '}
            {markOverdueTask && isTask(item) && (
              <OverdueLabel status={item.status} dueDate={item.dueDate} plantNow={plantNow} />
            )}
          </>
        )}
      </Typography>
    )
  }
}

export const generateCategoryColumn = (t: TFunction) => ({
  key: ColumnKeys.Category,
  label: <Label>{t('shiftEvent.label.category')}</Label>,
  width: FIX_COLUMN_WIDTH,
  sortable: true,
  labelKey: 'shiftEvent.label.category',
  customTemplate: (item: ShiftEvent) => (
    <Secondary>
      {item.category
        ? t(`shiftEvent.category.${item.category}`)
        : t('shiftEvent.action.noneOfThat')}
    </Secondary>
  )
})

export const generateEquipmentNumberColumn = (t: TFunction): Column<ShiftEvent> => ({
  key: ColumnKeys.EquipmentNumber,
  label: <Label>{t('shiftEvent.label.equipment')}</Label>,
  flex: 2,
  minWidth: FIX_COLUMN_WIDTH,
  sortable: true,
  customTemplate: ({equipment}: ShiftEvent) => {
    return (
      <Secondary>
        {!isEmpty(equipment) ? (
          <EquipmentLabel withId withText withTooltip withEllipsis equipment={equipment} />
        ) : (
          ''
        )}
      </Secondary>
    )
  }
})

export const generateStatusColumn = (
  t: TFunction,
  type?: 'small' | 'regular'
): Column<ShiftEvent> => ({
  headProps: type === 'small' ? {style: {paddingLeft: '4px'}} : {},
  key: ColumnKeys.Status,
  width: type === 'small' ? '0px' : undefined,
  label: <Label>{t('shiftEvent.label.status')}</Label>,
  sortable: true,
  customTemplate: (item: ShiftEvent) => (
    <Secondary>
      <EventStatusLabel status={item.status} iconOnly={type === 'small'} />
    </Secondary>
  )
})

export const generateDueDateColumn = (t: TFunction, timezone: string): Column<ShiftEvent> => ({
  key: 'dueDate',
  label: <Label>{t('shiftEvent.label.dueDate')}</Label>,
  flex: 2,
  sortable: true,
  customTemplate: (item: ShiftEvent) => (
    <Secondary sx={{whiteSpace: 'nowrap'}}>
      <DateTimeFormat date={(item as Task).dueDate} timezone={timezone} />
    </Secondary>
  )
})

export const generateProcessStageColumn = (t: TFunction): Column<ShiftEvent> => ({
  key: ColumnKeys.ProcessStage,
  label: <Label>{t('shiftEvent.label.processStage')}</Label>,
  flex: 2,
  sortable: true,
  customTemplate: (item: ShiftEvent) => {
    return (
      <Secondary>
        <EventProcessStageTitle stage={item.processStage} />
      </Secondary>
    )
  }
})

export const generateMaintenanceNotificationColumn = (
  t: TFunction,
  freeSearchText: string | undefined
): Column<ShiftEvent> => ({
  key: ColumnKeys.MaintenanceNotification,
  label: <Label>{t('shiftEvent.label.maintenanceNotification')}</Label>,
  flex: 2,
  sortable: true,
  customTemplate: (item: ShiftEvent) => {
    return (
      <Secondary>
        {freeSearchText && item.sapMaintenanceNotificationId
          ? getHighlightedTitle(item.sapMaintenanceNotificationId, freeSearchText)
          : item.sapMaintenanceNotificationId || '-'}
      </Secondary>
    )
  }
})

export const generateCreatedAtColumn = (t: TFunction, timezone: string): Column<ShiftEvent> => ({
  key: ColumnKeys.Created,
  label: <Label>{t('shiftEvent.label.createdAt')}</Label>,
  flex: 2,
  sortable: true,
  customTemplate: (item: ShiftEvent) => (
    <Secondary sx={{whiteSpace: 'nowrap'}}>
      {isStoppage(item) ? (
        <DateTimeFormat date={item.stoppageStart} timezone={timezone} />
      ) : isTask(item) ? (
        <DateTimeFormat date={item.dueDate} timezone={timezone} />
      ) : (
        <DateTimeFormat date={item.created} timezone={timezone} />
      )}
    </Secondary>
  )
})

export const generateEventTypeColum = (
  t: TFunction,
  type?: 'small' | 'regular'
): Column<ShiftEvent> => ({
  key: ColumnKeys.EventType,
  width: type === 'small' ? '0px' : undefined,
  label: <Label sx={{whiteSpace: 'nowrap'}}>{t('shiftEvent.label.eventType')}</Label>,
  sortable: true,
  customTemplate: (item: ShiftEvent) => (
    <Secondary>
      <EventTypeLabel eventType={item.eventType} iconOnly={type === 'small'} />
    </Secondary>
  )
})

export const generateWorkOrderEquipmentNumberColumn = <T extends {equipment?: Equipment}>(
  type: 'small' | 'regular',
  t: TFunction,
  freeSearchText?: string
): Column<T> => ({
  key: ColumnKeys.EquipmentNumber,
  sortable: true,
  label: <Label>{t('shiftEvent.label.equipment')}</Label>,
  minWidth: FIX_COLUMN_WIDTH,
  customTemplate: ({equipment}: T) => {
    return (
      <Secondary>
        {!isEmpty(equipment) ? (
          <EquipmentLabel
            fullWidth={type === 'regular'}
            withId
            withText
            withTooltip
            withEllipsis
            equipment={equipment}
            freeSearchText={freeSearchText}
          />
        ) : (
          ''
        )}
      </Secondary>
    )
  }
})

export const getTitleColumn = <T extends {title?: string}>(
  t: TFunction,
  freeSearchText?: string
): Column<T> => ({
  key: 'title',
  sortable: true,
  label: <Label>{t('shiftEvent.label.title')}</Label>,
  customTemplate: ({title}) => (
    <Secondary>
      {title ? (freeSearchText ? getHighlightedTitle(title, freeSearchText) : title) : '-'}
    </Secondary>
  )
})
