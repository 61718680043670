import {alpha, ToggleButton, ToggleButtonGroup} from '@mui/material'
import React, {FC} from 'react'
import {useTranslation} from 'react-i18next'

import {EventsTab} from '../../types/shiftHandover.types'

type ShiftEventsTabToggleProps = {
  activeTab: EventsTab
  setActiveTab: (tab: EventsTab) => void
}

export const ShiftEventsTabToggle: FC<ShiftEventsTabToggleProps> = ({activeTab, setActiveTab}) => {
  const {t} = useTranslation()

  return (
    <ToggleButtonGroup
      color="primary"
      sx={({palette}) => ({
        border: `1px solid ${alpha(palette.common.white, 0.125)}`
      })}
      fullWidth
      exclusive
      value={activeTab}
      onChange={(event: React.MouseEvent<HTMLElement>, value: EventsTab) => {
        if (value) setActiveTab(value)
      }}
    >
      {Object.values(EventsTab).map((codeType: EventsTab) => (
        <ToggleButton
          key={codeType}
          value={codeType}
          sx={({palette}) => ({
            color: palette.common.white,
            whiteSpace: 'nowrap',
            '&.MuiToggleButtonGroup-grouped:not(.Mui-selected)': {
              color: palette.common.white,
              border: 'none',
              '&:hover': {
                backgroundColor: 'transparent'
              }
            }
          })}
          data-test-id={`events-type-${codeType}`}
        >
          {t(`shiftEvent.tab.${codeType}`)}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  )
}
