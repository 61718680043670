import {EquipmentData, StoppageKind} from '@hconnect/common/types'

import {SapStatusCode} from '../../types/maintenanceNotification.types'
import {ShiftEvent} from '../../types/shiftHandover.types'

import {
  parameterChange,
  healthAndSafety,
  information,
  malfunction,
  task,
  dailyRecurringTasksList,
  monthlyRecurringTasksList,
  idea
} from './event'
import {shiftLeader} from './persona'

const equipmentTH01: EquipmentData = {
  id: 'TH01',
  idType: 'technicalId',
  text: 'text for TH01'
}

const equipmentWithoutTextTH01: EquipmentData = {
  id: 'TH01',
  idType: 'technicalId'
}

const equipmentSapNumber: EquipmentData = {
  id: '000000000080231381',
  idType: 'sapNumber',
  text: 'text for sap equipment'
}

export const events: ShiftEvent[] = [
  information,
  malfunction,
  parameterChange,
  healthAndSafety,
  idea,
  task,
  {
    id: '336',
    title: 'Ipsum laboriosam dolor.',
    description:
      'Neque ab voluptatum placeat fugiat veritatis ex aliquam sint mollitia magnam natus reprehenderit molestiae omnis vitae sit modi quae.',
    eventType: 'environment',
    category: 'mechanical',
    equipment: equipmentTH01,
    processStage: '40',
    status: 'ongoing',
    priority: 5,
    createdBy: {
      name: 'Hilda Blick',
      id: '7567'
    },
    modifiedBy: 'Hilda Blick',
    created: '2020-10-30T12:00:00.0000000+00:00',
    lastModified: '2020-10-30T12:00:00.0000000+00:00',
    isHighlighted: true,
    inforWorkOrder: {
      requestId: 'id-id'
    }
  },
  {
    id: '338',
    title: 'Consequatur reprehenderit eum repellat rerum perferendis.',
    description: 'Soluta perferendis et ut aliquam sit perferendis qui ipsa in illum nulla omnis.',
    eventType: 'information',
    category: 'electrical',
    equipment: equipmentTH01,
    processStage: '40',
    status: 'pending',
    priority: 1,
    createdBy: {
      name: 'Glenda Corwin',
      id: '7567'
    },
    modifiedBy: 'Glenda Corwin',
    created: '2020-10-30T12:00:00.0000000+00:00',
    lastModified: '2020-10-30T12:00:00.0000000+00:00',
    isHighlighted: true,
    inforWorkOrder: {
      requestId: 'id-id'
    }
  },
  {
    id: '340',
    title: 'Enim quis autem beatae.',
    description:
      'Sapiente inventore et quaerat pariatur omnis accusamus harum culpa cumque aut qui eum voluptatem distinctio quia nihil dolorum exercitationem.',
    eventType: 'information',
    category: 'other',
    equipment: equipmentTH01,
    processStage: '50',
    status: 'done',
    priority: 2,
    createdBy: {
      name: 'Sarah Murray',
      id: '7567'
    },
    modifiedBy: 'Sarah Murray',
    created: '2020-10-30T12:00:00.0000000+00:00',
    lastModified: '2020-10-30T12:00:00.0000000+00:00',
    isHighlighted: false,
    inforWorkOrder: {
      requestId: 'id-id'
    }
  },
  {
    id: '342',
    title: 'Ipsam maxime est consequatur.',
    description: 'Saepe ipsam voluptate itaque excepturi ut dolor adipisci dolore rerum culpa.',
    eventType: 'parameterChange',
    category: 'electrical',
    equipment: equipmentTH01,
    processStage: '60',
    status: 'ongoing',
    priority: 6,
    createdBy: {
      name: 'Cindy Luettgen',
      id: '7567'
    },
    modifiedBy: 'Cindy Luettgen',
    created: '2020-10-30T12:00:00.0000000+00:00',
    lastModified: '2020-10-30T12:00:00.0000000+00:00',
    isHighlighted: false,
    inforWorkOrder: {
      requestId: 'id-id'
    }
  },
  {
    id: '344',
    title: 'Est rem corrupti itaque.',
    description:
      'Recusandae repellendus consectetur repellat laudantium aut sequi non nulla distinctio.',
    eventType: 'task',
    category: 'mechanical',
    equipment: equipmentTH01,
    processStage: '10',
    status: 'ongoing',
    createdBy: {
      name: 'Lori Klocko',
      id: '7567'
    },
    modifiedBy: 'Lori Klocko',
    created: '2020-10-30T12:00:00.0000000+00:00',
    lastModified: '2020-10-30T12:00:00.0000000+00:00',
    assignees: [shiftLeader],
    dueDate: '2020-10-30T23:59:59.0000000+00:00',
    isHighlighted: false
  },
  {
    id: '346',
    title: 'Debitis beatae nihil.',
    description: 'Et itaque quam consequatur blanditiis impedit ea molestias repellendus non.',
    eventType: 'information',
    category: 'electrical',
    equipment: equipmentTH01,
    processStage: '50',
    status: 'done',
    priority: 6,
    createdBy: {
      name: 'Robin Stokes',
      id: '7567'
    },
    modifiedBy: 'Robin Stokes',
    created: '2020-10-30T12:00:00.0000000+00:00',
    lastModified: '2020-10-30T12:00:00.0000000+00:00',
    isHighlighted: false,
    inforWorkOrder: {
      requestId: 'id-id'
    }
  },
  {
    id: '348',
    title: 'Officia est tenetur repellat non.',
    description:
      'Qui voluptatem dolorem aut numquam temporibus minima deserunt error qui repudiandae dolorem perspiciatis qui aperiam corrupti.',
    eventType: 'parameterChange',
    category: 'mechanical',
    equipment: equipmentTH01,
    processStage: '10',
    status: 'pending',
    priority: 1,
    createdBy: {
      name: 'Joey Bergnaum',
      id: '7567'
    },
    modifiedBy: 'Joey Bergnaum',
    created: '2020-10-30T12:00:00.0000000+00:00',
    lastModified: '2020-10-30T12:00:00.0000000+00:00',
    isHighlighted: false,
    parameterChange: {
      needsApproval: true,
      approvers: [shiftLeader]
    },
    inforWorkOrder: {
      requestId: 'id-id'
    }
  },
  {
    id: '350',
    title: 'Architecto ipsam corporis adipisci et.',
    description: 'Ipsum fugiat sed quas voluptatum doloribus animi asperiores qui ipsum.',
    eventType: 'environment',
    category: 'process',
    equipment: equipmentTH01,
    processStage: '40',
    status: 'ongoing',
    priority: 2,
    createdBy: {
      name: 'Caleb Osinski',
      id: '7567'
    },
    modifiedBy: 'Caleb Osinski',
    created: '2020-10-30T12:00:00.0000000+00:00',
    lastModified: '2020-10-30T12:00:00.0000000+00:00',
    isHighlighted: false,
    inforWorkOrder: {
      requestId: 'id-id'
    }
  },
  {
    id: '352',
    title: 'Optio dicta animi.',
    description: 'Voluptates numquam excepturi voluptatem quidem eos ab velit nam aliquid est et.',
    eventType: 'parameterChange',
    category: 'mechanical',
    equipment: equipmentTH01,
    processStage: '40',
    status: 'pending',
    priority: 3,
    createdBy: {
      name: 'Faith Bogan',
      id: '7567'
    },
    modifiedBy: 'Faith Bogan',
    created: '2020-10-30T12:00:00.0000000+00:00',
    lastModified: '2020-10-30T12:00:00.0000000+00:00',
    isHighlighted: false,
    parameterChange: {
      needsApproval: true,
      approvers: [shiftLeader]
    },
    inforWorkOrder: {
      requestId: 'id-id'
    }
  },
  {
    id: '354',
    title: 'Tenetur sed modi voluptatem.',
    description: 'Est id et ipsa nostrum voluptate debitis sint rem nulla.',
    eventType: 'malfunction',
    category: 'electrical',
    equipment: equipmentTH01,
    processStage: '40',
    status: 'pending',
    priority: 5,
    createdBy: {
      name: 'Jerald Pouros',
      id: '7567'
    },
    modifiedBy: 'Jerald Pouros',
    created: '2020-10-30T12:00:00.0000000+00:00',
    lastModified: '2020-10-30T12:00:00.0000000+00:00',
    isHighlighted: false,
    inforWorkOrder: {
      requestId: 'id-id'
    }
  },
  {
    id: '356',
    title: 'Ullam asperiores nobis accusamus.',
    description:
      'Animi ut totam molestiae veritatis facilis ab dolores aspernatur suscipit ut eaque ut sed sed et unde omnis.',
    eventType: 'information',
    category: 'quality',
    equipment: equipmentTH01,
    processStage: '40',
    status: 'ongoing',
    priority: 1,
    createdBy: {
      name: 'Pearl Connel',
      id: '7567'
    },
    modifiedBy: 'Pearl Connel',
    created: '2020-10-30T12:00:00.0000000+00:00',
    lastModified: '2020-10-30T12:00:00.0000000+00:00',
    isHighlighted: false,
    inforWorkOrder: {
      requestId: 'id-id'
    }
  },
  {
    id: '358',
    title: 'Cumque culpa voluptatem.',
    description:
      'Dolorem nemo in deleniti cum mollitia eos nihil voluptas nisi quos laboriosam vel.',
    eventType: 'information',
    category: 'quality',
    equipment: equipmentTH01,
    processStage: '50',
    status: 'ongoing',
    priority: 3,
    createdBy: {
      name: 'Darrel Shields',
      id: '7567'
    },
    modifiedBy: 'Darrel Shields',
    created: '2020-10-30T12:00:00.0000000+00:00',
    lastModified: '2020-10-30T12:00:00.0000000+00:00',
    isHighlighted: false,
    inforWorkOrder: {
      requestId: 'id-id'
    }
  },
  {
    id: '360',
    title: 'Voluptas assumenda eaque dolor.',
    description: 'Hic sit rerum in dolorem fuga voluptatem tempore assumenda hic commodi porro.',
    eventType: 'information',
    category: 'environment',
    processStage: '40',
    status: 'pending',
    priority: 5,
    createdBy: {
      name: 'Terry Bartoletti',
      id: '7567'
    },
    modifiedBy: 'Terry Bartoletti',
    created: '2020-10-30T12:00:00.0000000+00:00',
    lastModified: '2020-10-30T12:00:00.0000000+00:00',
    isHighlighted: false,
    inforWorkOrder: {
      requestId: 'id-id'
    }
  },
  {
    id: '362',
    title: 'Sapiente molestiae quis modi.',
    description:
      'Optio veritatis autem velit molestiae quis velit consectetur inventore officiis laboriosam quia atque.',
    eventType: 'parameterChange',
    category: 'process',
    equipment: equipmentTH01,
    processStage: '60',
    status: 'done',
    priority: 5,
    createdBy: {
      name: 'Kevin Reynolds',
      id: '7567'
    },
    modifiedBy: 'Kevin Reynolds',
    created: '2020-10-30T12:00:00.0000000+00:00',
    lastModified: '2020-10-30T12:00:00.0000000+00:00',
    isHighlighted: false,
    parameterChange: {
      needsApproval: true,
      approvers: [shiftLeader]
    },
    inforWorkOrder: {
      requestId: 'id-id'
    }
  },
  {
    id: '364',
    title: 'Animi sunt fugiat.',
    description:
      'Rerum repudiandae est quia doloremque amet reiciendis laborum pariatur qui accusantium.',
    eventType: 'information',
    category: 'quality',
    equipment: equipmentTH01,
    processStage: '40',
    status: 'pending',
    priority: 4,
    createdBy: {
      name: 'Perry Ernser',
      id: '7567'
    },
    modifiedBy: 'Perry Ernser',
    created: '2020-10-30T12:00:00.0000000+00:00',
    lastModified: '2020-10-30T12:00:00.0000000+00:00',
    isHighlighted: false,
    inforWorkOrder: {
      requestId: 'id-id'
    }
  },
  {
    id: '366',
    title: 'Exercitationem explicabo tempore odit voluptatem.',
    description:
      'Aut nesciunt iusto hic sapiente quas voluptatem vel dolores quis velit expedita doloribus quia quia.',
    eventType: 'information',
    category: 'electrical',
    equipment: equipmentTH01,
    processStage: '40',
    status: 'ongoing',
    priority: 5,
    createdBy: {
      name: 'Enrique Haag',
      id: '7567'
    },
    modifiedBy: 'Enrique Haag',
    created: '2020-10-30T12:00:00.0000000+00:00',
    lastModified: '2020-10-30T12:00:00.0000000+00:00',
    isHighlighted: false,
    inforWorkOrder: {
      requestId: 'id-id'
    }
  },
  {
    id: '368',
    title: 'Maiores inventore harum nesciunt maiores.',
    description: 'Et aspernatur quae distinctio id ab ut est occaecati qui et enim.',
    eventType: 'information',
    category: 'other',
    equipment: equipmentTH01,
    processStage: '30',
    status: 'ongoing',
    priority: 4,
    createdBy: {
      name: 'Noah Buckridge',
      id: '7567'
    },
    modifiedBy: 'Noah Buckridge',
    created: '2020-10-30T12:00:00.0000000+00:00',
    lastModified: '2020-10-30T12:00:00.0000000+00:00',
    isHighlighted: false,
    inforWorkOrder: {
      requestId: 'id-id'
    }
  },
  {
    id: '370',
    title: 'Error quo sed consequatur sit.',
    description:
      'Voluptatem officia neque dolor enim tempore dolor facere et dolorem cum labore ad aliquid dolorum esse minima corporis assumenda officia.',
    eventType: 'malfunction',
    category: 'quality',
    equipment: equipmentTH01,
    processStage: '60',
    status: 'pending',
    priority: 1,
    createdBy: {
      name: 'Ginger Bechtelar',
      id: '7567'
    },
    modifiedBy: 'Ginger Bechtelar',
    created: '2020-10-30T12:00:00.0000000+00:00',
    lastModified: '2020-10-30T12:00:00.0000000+00:00',
    isHighlighted: false,
    inforWorkOrder: {
      requestId: 'id-id'
    }
  },
  {
    id: '372',
    title: 'Est reprehenderit molestiae id.',
    description:
      'Iure quaerat sed adipisci qui suscipit voluptatem quam eum itaque aut quia omnis saepe.',
    eventType: 'malfunction',
    category: 'quality',
    equipment: equipmentTH01,
    processStage: '30',
    status: 'done',
    priority: 3,
    createdBy: {
      name: 'Seth Kling',
      id: '7567'
    },
    modifiedBy: 'Seth Kling',
    created: '2020-10-30T12:00:00.0000000+00:00',
    lastModified: '2020-10-30T12:00:00.0000000+00:00',
    isHighlighted: false,
    inforWorkOrder: {
      requestId: 'id-id'
    }
  },
  {
    id: '374',
    title: 'Accusamus eos sequi.',
    description:
      'Debitis tenetur sunt sed aperiam repellendus qui quidem voluptatem quis minus asperiores incidunt quisquam unde veritatis soluta vitae ab.',
    eventType: 'information',
    category: 'quality',
    equipment: equipmentTH01,
    processStage: '60',
    status: 'ongoing',
    priority: 4,
    createdBy: {
      name: 'Bobby Miller',
      id: '7567'
    },
    modifiedBy: 'Bobby Miller',
    created: '2020-10-30T12:00:00.0000000+00:00',
    lastModified: '2020-10-30T12:00:00.0000000+00:00',
    isHighlighted: false,
    inforWorkOrder: {
      requestId: 'id-id'
    }
  },
  {
    id: '376',
    title: 'Suscipit aperiam molestias omnis voluptatum.',
    description:
      'Possimus cum molestias consequatur maiores et labore ab ut sed mollitia ipsa perspiciatis assumenda tempore excepturi.',
    eventType: 'healthAndSafety',
    category: 'electrical',
    equipment: equipmentTH01,
    processStage: '10',
    status: 'pending',
    priority: 5,
    createdBy: {
      name: 'Betsy McLaughlin',
      id: '7567'
    },
    modifiedBy: 'Betsy McLaughlin',
    created: '2020-10-30T12:00:00.0000000+00:00',
    lastModified: '2020-10-30T12:00:00.0000000+00:00',
    isHighlighted: false,
    inforWorkOrder: {
      requestId: 'id-id'
    }
  },
  {
    id: '378',
    title: 'Molestiae hic animi.',
    description:
      'Velit et voluptas nihil id quisquam molestiae et occaecati nulla delectus qui dolorem veniam.',
    eventType: 'information',
    category: 'mechanical',
    equipment: equipmentTH01,
    processStage: '50',
    status: 'done',
    priority: 3,
    createdBy: {
      name: 'Inez Klein',
      id: '7567'
    },
    modifiedBy: 'Inez Klein',
    created: '2020-10-30T12:00:00.0000000+00:00',
    lastModified: '2020-10-30T12:00:00.0000000+00:00',
    isHighlighted: false,
    inforWorkOrder: {
      requestId: 'id-id'
    }
  },
  {
    id: '380',
    title: 'Voluptas itaque deleniti.',
    description:
      'Qui pariatur dignissimos quaerat eos quibusdam tempore modi id nobis temporibus enim.',
    eventType: 'malfunction',
    category: 'process',
    equipment: equipmentTH01,
    processStage: '60',
    status: 'done',
    priority: 4,
    createdBy: {
      name: 'Irene Mertz',
      id: '7567'
    },
    modifiedBy: 'Irene Mertz',
    created: '2020-10-30T12:00:00.0000000+00:00',
    lastModified: '2020-10-30T12:00:00.0000000+00:00',
    isHighlighted: false,
    inforWorkOrder: {
      requestId: 'id-id'
    }
  },
  {
    id: '382',
    title: 'Odio similique qui nihil nisi.',
    description: 'Aut blanditiis veniam aut sit pariatur aut enim quidem dolor aut autem.',
    eventType: 'information',
    category: 'mechanical',
    equipment: equipmentTH01,
    processStage: '40',
    status: 'ongoing',
    priority: 6,
    createdBy: {
      name: 'Abel Bahringer',
      id: '7567'
    },
    modifiedBy: 'Abel Bahringer',
    created: '2020-10-30T12:00:00.0000000+00:00',
    lastModified: '2020-10-30T12:00:00.0000000+00:00',
    isHighlighted: false,
    inforWorkOrder: {
      requestId: 'id-id'
    }
  },
  {
    id: '384',
    title: 'Delectus maxime minus reiciendis nihil.',
    description:
      'Saepe in sit culpa tempora quaerat et inventore et nobis voluptatem eligendi autem et dolor nobis id quod animi possimus.',
    eventType: 'malfunction',
    category: 'mechanical',
    equipment: equipmentTH01,
    processStage: '40',
    status: 'pending',
    priority: 2,
    createdBy: {
      name: 'Sandy Purdy',
      id: '7567'
    },
    modifiedBy: 'Sandy Purdy',
    created: '2020-10-30T12:00:00.0000000+00:00',
    lastModified: '2020-10-30T12:00:00.0000000+00:00',
    isHighlighted: false,
    inforWorkOrder: {
      requestId: 'id-id'
    }
  },
  {
    id: '386',
    title: 'Nisi cumque culpa perspiciatis cupiditate.',
    description:
      'Cumque quas aut voluptas est optio rerum ut quam et error quasi et eos nihil exercitationem.',
    eventType: 'information',
    category: 'process',
    equipment: equipmentTH01,
    processStage: '60',
    status: 'pending',
    priority: 4,
    createdBy: {
      name: 'Tara Torp',
      id: '7567'
    },
    modifiedBy: 'Tara Torp',
    created: '2020-10-30T12:00:00.0000000+00:00',
    lastModified: '2020-10-30T12:00:00.0000000+00:00',
    isHighlighted: false,
    inforWorkOrder: {
      requestId: 'id-id'
    }
  },
  {
    id: '388',
    title: 'Eos aperiam fugiat saepe quibusdam.',
    description:
      'In animi maxime nobis ut quia rerum harum ex est sint corporis fugiat inventore temporibus nihil numquam mollitia.',
    eventType: 'malfunction',
    category: 'mechanical',
    equipment: equipmentTH01,
    processStage: '40',
    status: 'done',
    priority: 4,
    createdBy: {
      name: 'Naomi Trantow',
      id: '7567'
    },
    modifiedBy: 'Naomi Trantow',
    created: '2020-10-30T12:00:00.0000000+00:00',
    lastModified: '2020-10-30T12:00:00.0000000+00:00',
    isHighlighted: false,
    inforWorkOrder: {
      requestId: 'id-id'
    }
  },
  {
    id: '390',
    title: 'Nulla suscipit nesciunt aut.',
    description:
      'Et omnis sit et ut esse provident cumque dolor expedita at velit quidem sit itaque asperiores occaecati autem id nesciunt.',
    eventType: 'malfunction',
    category: 'electrical',
    equipment: equipmentTH01,
    processStage: '60',
    status: 'ongoing',
    priority: 2,
    createdBy: {
      name: 'Tina Powlowski',
      id: '7567'
    },
    modifiedBy: 'Tina Powlowski',
    created: '2020-10-30T12:00:00.0000000+00:00',
    lastModified: '2020-10-30T12:00:00.0000000+00:00',
    isHighlighted: false,
    inforWorkOrder: {
      requestId: 'id-id'
    }
  },
  {
    id: '392',
    title: 'Deserunt consequatur earum.',
    description:
      'Ut incidunt debitis enim magnam magnam magnam delectus similique omnis veritatis repellat eos et placeat nesciunt inventore ipsam ut et.',
    eventType: 'parameterChange',
    category: 'process',
    equipment: equipmentTH01,
    processStage: '30',
    status: 'ongoing',
    priority: 2,
    createdBy: {
      name: 'Gregory Zemlak',
      id: '7567'
    },
    modifiedBy: 'Gregory Zemlak',
    created: '2020-10-30T12:00:00.0000000+00:00',
    lastModified: '2020-10-30T12:00:00.0000000+00:00',
    isHighlighted: false,
    parameterChange: {
      needsApproval: true,
      approvers: [shiftLeader]
    },
    inforWorkOrder: {
      requestId: 'id-id'
    }
  },
  {
    id: '394',
    title: 'Voluptatem in quidem.',
    description:
      'Assumenda aut atque est nostrum at pariatur voluptatem perferendis tenetur eveniet deserunt exercitationem dignissimos beatae cumque.',
    eventType: 'information',
    category: 'environment',
    equipment: equipmentTH01,
    processStage: '60',
    status: 'done',
    priority: 1,
    createdBy: {
      name: 'Gayle Hettinger',
      id: '7567'
    },
    modifiedBy: 'Gayle Hettinger',
    created: '2020-10-30T12:00:00.0000000+00:00',
    lastModified: '2020-10-30T12:00:00.0000000+00:00',
    isHighlighted: false,
    inforWorkOrder: {
      requestId: 'id-id'
    }
  },
  {
    id: '396',
    title: 'Et illo dolorem qui.',
    description: 'Praesentium saepe quisquam vel et nisi rerum temporibus ipsum eius quae sit.',
    eventType: 'malfunction',
    category: 'process',
    equipment: equipmentTH01,
    processStage: '50',
    status: 'done',
    priority: 1,
    createdBy: {
      name: 'Louise Gislason',
      id: '7567'
    },
    modifiedBy: 'Louise Gislason',
    created: '2020-10-30T12:00:00.0000000+00:00',
    lastModified: '2020-10-30T12:00:00.0000000+00:00',
    isHighlighted: false,
    inforWorkOrder: {
      requestId: 'id-id'
    }
  },
  {
    id: '398',
    title: 'Labore placeat harum pariatur.',
    description: 'Consequatur adipisci qui omnis aut eos cum in est sunt harum.',
    eventType: 'parameterChange',
    category: 'process',
    equipment: equipmentTH01,
    processStage: '60',
    status: 'done',
    priority: 2,
    createdBy: {
      name: 'Charlene Orn',
      id: '7567'
    },
    modifiedBy: 'Charlene Orn',
    created: '2020-10-30T12:00:00.0000000+00:00',
    lastModified: '2020-10-30T12:00:00.0000000+00:00',
    isHighlighted: false,
    parameterChange: {
      needsApproval: true,
      approvers: [shiftLeader]
    },
    inforWorkOrder: {
      requestId: 'id-id'
    }
  },
  {
    id: '400',
    title: 'Voluptate rem et.',
    description:
      'Est doloribus deleniti sint consectetur voluptas velit cumque mollitia maxime dicta excepturi dolorem maiores qui.',
    eventType: 'information',
    category: 'other',
    equipment: equipmentTH01,
    processStage: '30',
    status: 'done',
    priority: 2,
    createdBy: {
      name: 'Bessie Littel',
      id: '7567'
    },
    modifiedBy: 'Bessie Littel',
    created: '2020-10-30T12:00:00.0000000+00:00',
    lastModified: '2020-10-30T12:00:00.0000000+00:00',
    isHighlighted: false,
    inforWorkOrder: {
      requestId: 'id-id'
    }
  },
  {
    id: '402',
    title: 'Sit minus est.',
    description:
      'Itaque quibusdam nesciunt voluptatem dolorem laboriosam natus numquam eius ut et velit ratione impedit.',
    eventType: 'environment',
    category: 'environment',
    equipment: equipmentTH01,
    processStage: '60',
    status: 'ongoing',
    priority: 1,
    createdBy: {
      name: 'Billie Dietrich',
      id: '7567'
    },
    modifiedBy: 'Billie Dietrich',
    created: '2020-10-29T02:48:57.6457363+00:00',
    lastModified: '2020-10-29T02:48:57.6457363+00:00',
    isHighlighted: false,
    inforWorkOrder: {
      requestId: 'id-id'
    }
  },
  {
    id: '404',
    title: 'Event created before the DST hour in Berlin',
    description: 'Et quia non enim velit eveniet eaque et porro error sit.',
    eventType: 'information',
    category: 'process',
    equipment: equipmentTH01,
    processStage: '30',
    status: 'pending',
    priority: 4,
    createdBy: {
      name: 'Randall Monahan',
      id: '7567'
    },
    modifiedBy: 'Randall Monahan',
    created: '2020-10-25T00:52:00Z',
    lastModified: '2020-10-25T00:53:00Z',
    isHighlighted: false,
    inforWorkOrder: {
      requestId: 'id-id'
    }
  },
  {
    id: '405',
    title: 'Event created during the DST hour in Berlin',
    description: 'Et quia non enim velit eveniet eaque et porro error sit.',
    eventType: 'information',
    category: 'process',
    equipment: equipmentTH01,
    processStage: '30',
    status: 'pending',
    priority: 4,
    createdBy: {
      name: 'Randall Monahan',
      id: '7567'
    },
    modifiedBy: 'Randall Monahan',
    created: '2020-10-25T01:53:00Z',
    lastModified: '2020-10-25T01:54:00Z',
    isHighlighted: false,
    inforWorkOrder: {
      requestId: 'id-id'
    }
  },
  {
    id: '406',
    title: 'Event modified in the DST hour in Berlin for sunday morning shift',
    description: 'Et quia non enim velit eveniet eaque et porro error sit.',
    eventType: 'information',
    category: 'process',
    equipment: equipmentTH01,
    processStage: '30',
    status: 'pending',
    priority: 4,
    createdBy: {
      name: 'Randall Monahan',
      id: '7567'
    },
    modifiedBy: 'Randall Monahan',
    created: '2020-10-25T00:52:00Z',
    lastModified: '2020-10-25T07:00:00Z',
    isHighlighted: false,
    inforWorkOrder: {
      requestId: 'id-id'
    }
  },
  {
    id: '407',
    title: 'Event created in the DST hour in Berlin for sunday morning shift',
    description: 'Et quia non enim velit eveniet eaque et porro error sit.',
    eventType: 'information',
    category: 'process',
    equipment: equipmentSapNumber,
    processStage: '30',
    status: 'pending',
    priority: 4,
    createdBy: {
      name: 'Randall Monahan',
      id: '7567'
    },
    modifiedBy: 'Randall Monahan',
    created: '2020-10-25T08:53:00Z',
    lastModified: '2020-10-25T08:53:00Z',
    isHighlighted: false,
    inforWorkOrder: {
      requestId: 'id-id'
    }
  },
  {
    id: '408',
    title: 'Event modified in the DST hour in Berlin for monday morning shift',
    description: 'Et quia non enim velit eveniet eaque et porro error sit.',
    eventType: 'information',
    category: 'process',
    equipment: equipmentTH01,
    processStage: '30',
    status: 'pending',
    priority: 4,
    createdBy: {
      name: 'Randall Monahan',
      id: '7567'
    },
    modifiedBy: 'Randall Monahan',
    created: '2020-10-25T08:53:00Z',
    lastModified: '2020-10-26T08:53:00Z',
    isHighlighted: false,
    inforWorkOrder: {
      requestId: 'id-id'
    }
  },
  {
    id: '409',
    title: 'Event created in the DST hour in Berlin for friday morning shift',
    description: 'Et quia non enim velit eveniet eaque et porro error sit.',
    eventType: 'information',
    category: 'process',
    equipment: equipmentTH01,
    processStage: '30',
    status: 'pending',
    priority: 4,
    createdBy: {
      name: 'Randall Monahan',
      id: '7567'
    },
    modifiedBy: 'Randall Monahan',
    created: '2020-10-23T08:53:00Z',
    lastModified: '2020-10-23T08:53:00Z',
    isHighlighted: false,
    inforWorkOrder: {
      requestId: 'id-id'
    }
  },
  {
    id: '410',
    title: 'Event created in the DST hour in Berlin for thursday morning shift',
    description: 'Et quia non enim velit eveniet eaque et porro error sit.',
    eventType: 'information',
    category: 'process',
    equipment: equipmentSapNumber,
    processStage: '30',
    status: 'pending',
    priority: 4,
    createdBy: {
      name: 'Randall Monahan',
      id: '7567'
    },
    modifiedBy: 'Randall Monahan',
    created: '2020-10-08T08:53:00Z',
    lastModified: '2020-10-08T08:53:00Z',
    isHighlighted: false,
    inforWorkOrder: {
      requestId: 'id-id'
    }
  },
  {
    id: '411',
    title: 'Rem corrupti sit sit, itaque.',
    description:
      'Recusandae repellendus consectetur repellat laudantium aut sequi non nulla distinctio.',
    eventType: 'parameterChange',
    category: 'quality',
    equipment: equipmentWithoutTextTH01,
    processStage: '10',
    status: 'ongoing',
    createdBy: {
      name: 'Lori Klocko',
      id: '7567'
    },
    modifiedBy: 'Lori Klocko',
    created: '2020-10-30T12:00:00.0000000+00:00',
    lastModified: '2020-10-30T12:00:00.0000000+00:00',
    isHighlighted: false,
    parameterChange: {
      needsApproval: true,
      approvers: [shiftLeader]
    },
    inforWorkOrder: {
      requestId: 'id-id'
    }
  },
  {
    id: '412',
    title: 'Itaque atque dicta repudiandae.',
    description:
      'Dolorem et sequi eligendi alias sed officia maiores aspernatur fuga at natus reprehenderit similique ut quia ipsum quia ullam.',
    eventType: 'parameterChange',
    category: 'quality',
    equipment: equipmentTH01,
    processStage: '30',
    status: 'ongoing',
    priority: 3,
    createdBy: {
      name: 'Ed Kozey',
      id: '7567'
    },
    modifiedBy: 'Ed Kozey',
    created: '2020-10-30T12:00:00.0000000+00:00',
    lastModified: '2020-10-30T12:00:00.0000000+00:00',
    isHighlighted: false,
    parameterChange: {
      needsApproval: true,
      approvers: [shiftLeader]
    },
    inforWorkOrder: {
      requestId: 'id-id'
    }
  },
  {
    id: '413',
    title: 'Unplanned kiln stoppage event',
    description: null,
    eventType: StoppageKind.Incident,
    category: 'other',
    equipment: equipmentTH01,
    processStage: '10',
    status: 'none',
    priority: 1,
    createdBy: {
      name: 'Lance Rippin',
      id: '7567'
    },
    modifiedBy: 'Lance Rippin',
    created: '2020-10-30T12:00:00.0000000+00:00',
    lastModified: '2020-10-30T12:00:00.0000000+00:00',
    stoppageType: 'kiln',
    stoppageCode: 'S2415',
    stoppageStart: '2020-10-18T11:00:00.0000000+00:00',
    isHighlighted: false
  },
  {
    id: '414',
    title: 'Unplanned other stoppage event',
    description: 'Sample description',
    eventType: StoppageKind.Incident,
    category: 'other',
    equipment: equipmentTH01,
    processStage: '10',
    status: 'none',
    priority: 1,
    createdBy: {
      name: 'Lance Rippin',
      id: '7567'
    },
    modifiedBy: 'Lance Rippin',
    created: '2020-10-30T12:00:00.0000000+00:00',
    lastModified: '2020-10-30T12:00:00.0000000+00:00',
    stoppageStart: '2020-10-18T11:00:00.0000000+00:00',
    stoppageType: 'other',
    stoppageReason: 'unplanned things happen',
    isHighlighted: false
  },
  {
    id: '415',
    title: 'Temporibus quo reiciendis molestiae.',
    description:
      'Voluptas sed nemo facere omnis eos esse animi ut non dolorum nobis molestias debitis sed dolor aut quod eius quo.',
    eventType: 'malfunction',
    category: 'mechanical',
    equipment: equipmentTH01,
    processStage: '60',
    status: 'ongoing',
    priority: 4,
    createdBy: {
      name: 'Lance Rippin',
      id: '7567'
    },
    modifiedBy: 'Lance Rippin',
    created: '2020-10-30T12:00:00.0000000+00:00',
    lastModified: '2020-10-30T12:00:00.0000000+00:00',
    isHighlighted: false,
    inforWorkOrder: {
      requestId: 'id-id'
    }
  },
  {
    id: '416',
    title: 'Event created in the Winter hour in Berlin for Sunday morning shift',
    created: '2020-11-08T08:53:00Z',
    lastModified: '2020-11-08T08:53:00Z',
    description: 'Et quia non enim velit eveniet eaque et porro error sit.',
    eventType: 'information',
    category: 'process',
    equipment: equipmentSapNumber,
    processStage: '30',
    status: 'pending',
    priority: 4,
    createdBy: {
      name: 'Randall Monahan',
      id: '7567'
    },
    modifiedBy: 'Randall Monahan',
    isHighlighted: false,
    inforWorkOrder: {
      requestId: 'id-id'
    }
  },
  {
    id: '417',
    title: 'Fusce ipsum odio.',
    description: 'Morbi leo elit, elementum nec sodales et, aliquam sed magna.',
    eventType: 'environment',
    category: 'mechanical',
    equipment: equipmentTH01,
    processStage: '40',
    status: 'ongoing',
    priority: 5,
    createdBy: {
      name: 'Pamela Brett',
      id: '7567'
    },
    modifiedBy: 'Pamela Brett',
    created: '2022-10-30T12:00:00.0000000+00:00',
    lastModified: '2022-10-30T12:00:00.0000000+00:00',
    isHighlighted: true,
    sapStatusCode: SapStatusCode.ON_GOING,
    sapMaintenanceNotificationId: '000011572908',
    inforWorkOrder: {
      requestId: 'id-id'
    }
  },
  {
    id: '418',
    title: 'Fusce ipsum odio.',
    description: 'Morbi leo elit, elementum nec sodales et, aliquam sed magna.',
    eventType: 'environment',
    category: 'mechanical',
    equipment: equipmentTH01,
    processStage: '40',
    status: 'ongoing',
    priority: 5,
    createdBy: {
      name: 'Pamela Brett',
      id: '7567'
    },
    modifiedBy: 'Pamela Brett',
    created: '2022-10-30T11:00:00.0000000+00:00',
    lastModified: '2022-10-30T11:00:00.0000000+00:00',
    isHighlighted: true,
    sapStatusCode: SapStatusCode.PENDING,
    sapMaintenanceNotificationId: '000011572908',
    inforWorkOrder: {
      requestId: 'id-id'
    }
  },
  {
    id: '419',
    title: 'Fusce ipsum odio.',
    description: 'Morbi leo elit, elementum nec sodales et, aliquam sed magna.',
    eventType: 'environment',
    category: 'mechanical',
    equipment: equipmentTH01,
    processStage: '40',
    status: 'ongoing',
    priority: 5,
    createdBy: {
      name: 'Pamela Brett',
      id: '7567'
    },
    modifiedBy: 'Pamela Brett',
    created: '2022-10-30T10:00:00.0000000+00:00',
    lastModified: '2022-10-30T10:00:00.0000000+00:00',
    isHighlighted: true,
    sapStatusCode: SapStatusCode.DONE,
    sapMaintenanceNotificationId: '000011572908',
    inforWorkOrder: {
      requestId: 'id-id'
    }
  },
  {
    id: '420',
    title: 'Fusce ipsum odio.',
    description: 'Morbi leo elit, elementum nec sodales et, aliquam sed magna.',
    eventType: 'environment',
    category: 'mechanical',
    equipment: equipmentTH01,
    processStage: '40',
    status: 'ongoing',
    priority: 5,
    createdBy: {
      name: 'Pamela Brett',
      id: '7567'
    },
    modifiedBy: 'Pamela Brett',
    created: '2022-10-30T10:30:00.0000000+00:00',
    lastModified: '2022-10-30T10:30:00.0000000+00:00',
    isHighlighted: true,
    sapStatusCode: SapStatusCode.SUBMITTED,
    sapMaintenanceNotificationId: '000011572908',
    inforWorkOrder: {
      requestId: 'id-id'
    }
  },
  ...dailyRecurringTasksList,
  ...monthlyRecurringTasksList
]
