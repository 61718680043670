import {DateRange} from '@hconnect/common/types'
import {AxiosError} from 'axios'
import {useQuery, UseQueryOptions} from 'react-query'

import {QuickSelectSlots} from '../../common/utils/filterParameter'
import {SummaryPageData, SummaryPageDataSearchCriteria} from '../../types/shiftHandover.types'
import {queryRangeToTimeRange} from '../../utils/timeRangeHelper'
import {useConfig} from '../useConfig'

import {useApi} from './useApi'

export const QueryKey = 'runningtimes-summarypage'

export const useShiftSummary = (
  queryRange: DateRange | QuickSelectSlots,
  options?: UseQueryOptions<SummaryPageData, AxiosError>
) => {
  const {axiosInstance} = useApi()
  const config = useConfig()
  const {utcNow, shifts, timezone} = config
  const path = `/shifthandover/${config.plantId}/shiftsummary`

  return useQuery<SummaryPageData, AxiosError>(
    [QueryKey, queryRange, timezone, shifts, config.plantId],
    async () => {
      const timeRange = queryRangeToTimeRange(queryRange, shifts, timezone, utcNow())

      const settings: SummaryPageDataSearchCriteria = {
        startDate: timeRange.startDate,
        endDate: timeRange.endDate
      }

      const {data} = await axiosInstance.post<SummaryPageData>(path, settings)

      return data
    },
    options
  )
}
