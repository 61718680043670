import {ConfirmationDialog, ErrorDialog} from '@hconnect/common/components/dialogs'
import {useEventDelete} from '@hconnect/common/hooks/useEventDelete'
import React from 'react'

import {useTaskScheduleDelete} from '../../hooks/api/useEventDelete'
import {useHasPermission} from '../../hooks/usePermission'
import {ShiftEvent} from '../../types/shiftHandover.types'

type Props = {
  event: ShiftEvent
  showDialog: boolean
  plantId: string
  setDialogOpen: (state: boolean) => void
  onDelete: (event: ShiftEvent) => void
  onlyDeleteSeries?: boolean
}

export const EventDelete: React.FC<Props> = ({
  event,
  onDelete,
  showDialog,
  setDialogOpen,
  onlyDeleteSeries,
  plantId
}) => {
  const hasPermission = useHasPermission()
  const eventDeleteAction = useEventDelete(plantId, {
    onSuccess: () => onDelete(event)
  })
  const seriesDeleteAction = useTaskScheduleDelete({
    onSuccess: () => onDelete(event)
  })

  const isAllowed =
    event.eventType === 'task'
      ? hasPermission('DELETE_PLANT_TASKS')
      : hasPermission('DELETE_PLANT_EVENTS')

  if (!isAllowed || !event.id) return null

  const isSeriesItem = !!event?.['repetitionInfo']

  return (
    <>
      {eventDeleteAction.error ? (
        <ErrorDialog
          httpCode={eventDeleteAction.error.code}
          statusCode={eventDeleteAction.error?.response?.status}
          onClose={() => {
            setDialogOpen(false)
            eventDeleteAction.reset()
          }}
          errorText={eventDeleteAction.error.message}
        />
      ) : seriesDeleteAction.error ? (
        <ErrorDialog
          httpCode={seriesDeleteAction.error.code}
          statusCode={seriesDeleteAction.error?.response?.status}
          onClose={() => {
            setDialogOpen(false)
            seriesDeleteAction.reset()
          }}
          errorText={seriesDeleteAction.error.message}
        />
      ) : (
        // show error or confirm dialog but not both
        showDialog && (
          <ConfirmationDialog
            onDeleteSingle={() => {
              eventDeleteAction.mutate(event.id)
            }}
            onDeleteSeries={() => {
              seriesDeleteAction.mutate(event.id)
            }}
            onlyDeleteSeries={onlyDeleteSeries}
            onClose={() => setDialogOpen(false)}
            isSeriesItem={isSeriesItem}
            isSingleLoading={eventDeleteAction.isLoading}
            isSeriesLoading={seriesDeleteAction.isLoading}
            title={event.title ?? ''}
          />
        )
      )}
    </>
  )
}
