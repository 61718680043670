import {TaskRepetitionLabel} from '@hconnect/common/components/shiftEventDetails/TaskRepetitionLabel'
import {CustomTaskRepetitionDropDown} from '@hconnect/common/components/shiftEventFormFields'
import {CustomTaskRepetition} from '@hconnect/common/types'
import {IsoWeekDay, GridSetting} from '@hconnect/uikit'
import {WeekDaySelect} from '@hconnect/uikit/src/lib2'
import CheckIcon from '@mui/icons-material/Check'
import CancelIcon from '@mui/icons-material/Close'
import {Button, Dialog, DialogContent, DialogTitle, DialogActions, Grid, Box} from '@mui/material'
import {isArray} from 'lodash'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'

import {
  changeCustomTaskRepetition,
  getDefaultWeekdays
} from '../../common/utils/taskRepetitionHelper'
import {useConfig} from '../../hooks/useConfig'
import {isCustomWeeklyRepetition} from '../../types/taskRepetitionInfo.types'
import {ShiftPicker} from '../dateTimeAndShift/ShiftPicker'

import {TaskRepetitionEndingOptions} from './TaskRepetitionEndingOptions'
import {TaskRepetitionInterval} from './TaskRepetitionInterval'

type Props = {
  customTaskRepetition: CustomTaskRepetition
  onClose: () => void
  onChange: (next: CustomTaskRepetition) => void
}

type CustomRecurringTaskProps = {
  settings: CustomTaskRepetition
  onChange: (next: CustomTaskRepetition) => void
}

const regularSize: GridSetting = {xs: 12}

const CustomRecurringTask: React.FC<CustomRecurringTaskProps> = ({settings, onChange}) => {
  const {timezone, plantId} = useConfig()
  const {t} = useTranslation()

  return (
    <Grid container justifyContent="flex-start" alignItems="center" spacing={2}>
      <Grid item {...regularSize}>
        <ShiftPicker
          label={t('recurringDialog.startsOn')}
          utcDate={settings.startDate}
          onChange={(particularShift) => {
            if (isArray(particularShift)) return
            const next = {
              ...settings,
              startDate: particularShift.utcEndDate
            }

            if (isCustomWeeklyRepetition(next) && next.weekDays.length === 1) {
              next.weekDays = getDefaultWeekdays(next.startDate, timezone)
            }

            onChange(next)
          }}
        />
      </Grid>
      <Grid item {...regularSize}>
        <CustomTaskRepetitionDropDown
          data-test-id="task-custom-repetition-unit"
          onChange={(nextRepetition) => {
            if (nextRepetition === settings.repetition) return

            const next = changeCustomTaskRepetition(nextRepetition, settings, timezone)
            onChange(next)
          }}
          value={settings.repetition}
          required
        />
      </Grid>
      <Grid
        item
        {...regularSize}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center'
        }}
      >
        <Box component={'span'} width={50}>
          {t('recurringDialog.interval')}
        </Box>
        <TaskRepetitionInterval
          sx={{
            width: 75,
            '&.MuiFormControl-root': {
              mr: 1
            }
          }}
          settings={settings}
          onChange={(nextInterval) => onChange({...settings, repeatEvery: nextInterval})}
        />
      </Grid>

      {isCustomWeeklyRepetition(settings) && (
        <Grid
          item
          {...regularSize}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
          }}
        >
          <Box component={'span'} width={50}>
            {t('recurringDialog.onWeekday')}
          </Box>
          <WeekDaySelect
            selectedDays={settings.weekDays}
            onChange={(weekDays) => {
              if (weekDays.length > 0) onChange({...settings, weekDays})
            }}
            weekDayLabel={(day: IsoWeekDay) => t(`datePicker.weekdaysShort.${day}`)}
          />
        </Grid>
      )}

      <Grid
        item
        {...regularSize}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center'
        }}
      >
        <Box component={'span'} width={50}>
          {t('recurringDialog.ends')}
        </Box>
        <TaskRepetitionEndingOptions onChange={onChange} settings={settings} />
      </Grid>

      {settings.repetition && (
        <Grid item {...regularSize}>
          <TaskRepetitionLabel
            settings={settings}
            omitFirstOccurrence
            plantId={plantId}
            timezone={timezone}
          />
        </Grid>
      )}
    </Grid>
  )
}

export const TaskRepetitionDialog: React.FC<Props> = (props) => {
  const {t} = useTranslation()
  const [settings, updateCustomTaskRepetition] = useState<CustomTaskRepetition>(
    props.customTaskRepetition
  )

  return (
    <Dialog maxWidth="xs" data-test-id="task-custom-repetition-modal" onClose={props.onClose} open>
      <DialogTitle>{t('recurringDialog.title')}</DialogTitle>
      <DialogContent>
        <CustomRecurringTask settings={settings} onChange={updateCustomTaskRepetition} />
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose} startIcon={<CancelIcon />}>
          {t('action.cancel')}
        </Button>
        <Button onClick={() => props.onChange(settings)} color="primary" startIcon={<CheckIcon />}>
          {t('shiftEvent.action.apply')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
