import {FilterTimeRange} from '@hconnect/common/types'
import {Shift} from '@hconnect/common/types/shift.types'
import {fromBrowsertimeToPlantTime} from '@hconnect/common/utils'
import type {Dates} from '@hconnect/uikit'
import {AxiosError} from 'axios'
import {Moment} from 'moment-timezone'
import {useQuery, UseQueryOptions} from 'react-query'

import {FilterOptions, getQuickSelectFilter} from '../../common/utils/filterParameter'
import {TimeZone} from '../../types/atomic.types'
import {
  EventSearchCriteria,
  EventSearchResult,
  SortBy,
  Iso8601,
  SortOrder
} from '../../types/shiftHandover.types'
import {
  stoppageCodeSearchParamsToFilterParams,
  maintenanceNotificationSearchParamsToFilterParams
} from '../../utils/stoppageHelper'
import {useConfig} from '../useConfig'

import {useApi} from './useApi'

export const QueryKey = 'events-search'

// DatePicker will always use your browser timezone for start/end date through the calendar selection
// we need to alter those to match the actual timezone of the plant
function normalizeDateRange(range: Dates, toTimezone: string) {
  if (!range.startDate || !range.endDate)
    throw new Error('This function works only with specified ranges')
  const browsersTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone
  return {
    startDate: fromBrowsertimeToPlantTime(
      range.startDate.clone(),
      browsersTimezone,
      toTimezone
    ).toISOString(),
    endDate: fromBrowsertimeToPlantTime(
      range.endDate.clone(),
      browsersTimezone,
      toTimezone
    ).toISOString()
  }
}

export const generateTimeRange = (
  input: FilterOptions,
  normalizeForDatePicker: boolean,
  timezone: TimeZone,
  shifts: Shift[],
  utcNow: () => Moment
): FilterTimeRange => {
  let timeRange: {
    startDate: Iso8601 | null
    endDate: Iso8601 | null
  }

  if (typeof input.timeRange === 'object') {
    if (normalizeForDatePicker) {
      timeRange = normalizeDateRange(input.timeRange, timezone)
    } else {
      timeRange = {
        startDate: input.timeRange.startDate.toISOString(),
        endDate: input.timeRange.endDate.toISOString()
      }
    }
  } else if (typeof input.timeRange === 'string') {
    const [startDate, endDate] = getQuickSelectFilter(input.timeRange, shifts, utcNow(), timezone)
    timeRange = {startDate: startDate.toISOString(), endDate: endDate.toISOString()}
  } else {
    timeRange = {startDate: null, endDate: null}
  }

  return timeRange
}

export const useEventsSearch = (
  input: FilterOptions,
  normalizeForDatePicker = false,
  sortBy: SortBy = SortBy.Details,
  sortOrder?: SortOrder,
  pageNumber = 0,
  itemsPerPage = 50,
  options?: UseQueryOptions<EventSearchResult, AxiosError>
) => {
  const {axiosInstance} = useApi()
  const config = useConfig()
  const {utcNow, shifts, timezone, plantId} = config

  return useQuery<EventSearchResult, AxiosError>(
    [
      QueryKey,
      input,
      pageNumber,
      itemsPerPage,
      timezone,
      shifts,
      sortBy,
      sortOrder,
      normalizeForDatePicker,
      plantId
    ],
    async () => {
      const timeRange: FilterTimeRange = generateTimeRange(
        input,
        normalizeForDatePicker,
        timezone,
        shifts,
        utcNow
      )

      const settings: EventSearchCriteria = {
        timeRange,
        processStage: input.processStage,
        equipment: input.equipment,
        mainEquipment: input.mainEquipment,
        eventType: input.eventType,
        priority: input.priority,
        status: input.status,
        category: input.category,
        pageNumber,
        itemsPerPage,
        sortBy,
        sortOrder,
        stoppageCodesFilter: stoppageCodeSearchParamsToFilterParams(input.stoppageCode),
        freeText: input.freeText,
        maintenanceNotificationExists: maintenanceNotificationSearchParamsToFilterParams(
          input.maintenanceNotificationExists
        )
      }

      const response = await axiosInstance.post<EventSearchResult>(
        `/shifthandover/${plantId}/search/events`,
        settings
      )
      return response.data
    },
    {
      ...options
    }
  )
}
