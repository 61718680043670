import {AxiosInstance} from 'axios'

import {prepareAttachmentsForMocks} from '../../__mock__/businessLogic/attachmentsUtil'
import {mockStore} from '../../__mock__/mockStoreSingleton'
import {prepareEventFormData} from '../../common/utils/formData'
import {getEventBackendEndpoint} from '../../consts'
import {EventUpdate, ShiftEvent} from '../../types/shiftHandover.types'

export const updateEvent = async (
  axiosInstance: AxiosInstance,
  plantId: string,
  event: EventUpdate,
  scheduleRecurringTask = false
): Promise<ShiftEvent> => {
  const formData = await prepareEventFormData(event, ['attachments'])

  // to handle the specific case of series task (like changing the title of all of them at once)
  // the backend team decided to use a dedicated endpoint with the same DTO as for a single event
  const path = getEventBackendEndpoint(plantId, event.eventType, scheduleRecurringTask)

  if (mockStore.isEnabled()) {
    // for mocks, we convert the attachments to base64 so we can decode this in the mock store later
    const attachmentsForMock = event.newAttachments?.map((attachment) => attachment.originalFile)
    event.newAttachments = (await prepareAttachmentsForMocks(
      attachmentsForMock?.filter(
        (attachmentForMock): attachmentForMock is File => !!attachmentForMock
      )
      // eslint-disable-next-line
    )) as any

    // for mocks we also send a JSON Dto instead of form data since we can't handle formData in the mocks
    const response = await axiosInstance.put<ShiftEvent>(path, event)
    return response.data
  } else {
    const response = await axiosInstance.put<ShiftEvent>(path, formData)
    return response.data
  }
}
