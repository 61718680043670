import {Stoppage, StoppageKind, shifthandoverStoppagesList} from '@hconnect/common/types'

import {BaseEvent, Task, Information, ParameterChange, Idea} from '../../types/shiftHandover.types'

export const isStoppageEventType = (type: unknown): type is StoppageKind =>
  shifthandoverStoppagesList.includes(type as StoppageKind)

export const isStoppage = (event: BaseEvent): event is Stoppage =>
  isStoppageEventType(event.eventType)

export const isPartialStoppage = (event: Partial<BaseEvent>): event is Partial<Stoppage> =>
  isStoppageEventType(event.eventType)

export function isTask(event: BaseEvent): event is Task {
  return event.eventType === 'task'
}

export function isInformation(event: BaseEvent): event is Information {
  return event.eventType === 'information'
}

export function isIdea(event: BaseEvent): event is Idea {
  return event.eventType === 'idea'
}

export function isParameterChange(event: BaseEvent): event is ParameterChange {
  return event.eventType === 'parameterChange'
}
