// add here flags that should be on for all environments
import {FeatureFlag} from '@hconnect/common/types'

import {FeatureFlagKey} from '../../types/shiftHandover.types'

// usually at the end of the live-cycle of the feature flag, short before removal
const defaultFlags: FeatureFlag<FeatureFlagKey> = {
  sapMaintenanceNotification: {whitelist: [], blacklist: []},
  inforMaintenanceNotification: {whitelist: [], blacklist: []},
  notifications: {whitelist: [], blacklist: []},
  productionVolumes: {whitelist: [], blacklist: []},
  movieAttachment: {whitelist: [], blacklist: []}
}

// define here, which Flags should be enabled for each REACT_APP_STAGE
const stageSpecific: Record<string, FeatureFlag<FeatureFlagKey>> = {
  qa: {
    allProcessStagesSummary: {whitelist: ['HU11'], blacklist: []},
    expertSystem: {whitelist: [], blacklist: []}
  },
  uat: {
    allProcessStagesSummary: {whitelist: ['HU11'], blacklist: []}
  },
  prod: {
    allProcessStagesSummary: {whitelist: ['HU11'], blacklist: []}
  }
}

// should be used by the config request function only
export const getDefaultFeatureFlags = (baseFlags = defaultFlags) => {
  const stage = process.env.REACT_APP_STAGE
  const stageSpecificFlags = (stage && stageSpecific[stage]) || {}

  // TBD add here a way for the mock backend to override it
  // that way we could remove it from the config handling
  // which is currently used to inject the MockFlags into the system via the config endpoint
  // and create a provider instead

  return {...stageSpecificFlags, ...baseFlags}
}
