import {ViewMode} from '@hconnect/common/components/eventsList/types'
import {useSorting} from '@hconnect/common/hooks/useSorting'
import {DataTable, PaginationOptions} from '@hconnect/uikit/src/lib2'
import {Box, SortDirection} from '@mui/material'
import {TFunction} from 'i18next'
import {get, orderBy} from 'lodash'
import {Moment} from 'moment-timezone'
import React, {FC, useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import {isStoppage, isTask} from '../../common/utils/eventType'
import {
  generateTitleColumn,
  generateStatusColumn,
  generateCategoryColumn,
  generateDueDateColumn,
  generateEquipmentNumberColumn,
  generateProcessStageColumn
} from '../../components/tableHelpers'
import {TaskSchedulesResult} from '../../hooks/api/types'
import {useConfig} from '../../hooks/useConfig'
import {rowSx} from '../../layouts/EventsTable'
import {ShiftEvent} from '../../types/shiftHandover.types'
import {ColumnsMap, TableSize} from '../../types/table.types'

export const generateColumnsMap = (t: TFunction, timezone: string, plantNow: Moment) => {
  const tableSizeColumnsMap: ColumnsMap<ShiftEvent> = {
    XS: [
      generateTitleColumn({
        translation: t,
        type: 'withEquipment',
        markOverdueTask: false,
        plantNow
      })
    ],
    S: [
      generateTitleColumn({translation: t, type: 'withEquipment', markOverdueTask: false, plantNow})
    ],
    M: [
      generateTitleColumn({translation: t, type: 'regular', plantNow}),
      generateEquipmentNumberColumn(t),
      generateCategoryColumn(t),
      generateStatusColumn(t, 'small')
    ],
    L: [
      generateTitleColumn({translation: t, type: 'regular', markOverdueTask: false, plantNow}),
      generateDueDateColumn(t, timezone),
      generateEquipmentNumberColumn(t),
      generateCategoryColumn(t),
      generateStatusColumn(t, 'small')
    ],
    XL: [
      generateTitleColumn({translation: t, type: 'regular', markOverdueTask: false, plantNow}),
      generateDueDateColumn(t, timezone),
      generateEquipmentNumberColumn(t),
      generateCategoryColumn(t),
      generateProcessStageColumn(t),
      generateStatusColumn(t, 'regular')
    ]
  }

  return tableSizeColumnsMap
}

export type RecurringTasksProps = {
  isLoading: boolean
  data?: TaskSchedulesResult
  paginationOptions: PaginationOptions
  openDetailsMode: (schedule: ShiftEvent) => void
  viewMode: ViewMode
}

type RecurringTasksTableProps = RecurringTasksProps & {
  tableSize: TableSize
}

type EventTableColumnKey =
  | 'type'
  | 'title'
  | 'equipmentNumber'
  | 'processStage'
  | 'category'
  | 'dueDate'
  | 'status'
  | 'created'

export const sortEvents = (
  events: ShiftEvent[],
  orderByKey: EventTableColumnKey,
  sortDir: SortDirection
): ShiftEvent[] =>
  orderBy(
    events,
    [
      (event) => {
        if (orderByKey === 'equipmentNumber') {
          const equipment = get(event, 'equipment.id', '') || get(event, 'equipment.text', '')
          return equipment.toLowerCase()
        }
        if (orderByKey === 'created') {
          return isStoppage(event)
            ? get(event, 'stoppageStart')
            : isTask(event)
              ? get(event, 'dueDate')
              : get(event, 'created')
        }

        return get(event, orderByKey, '').toLowerCase()
      }
    ],
    [sortDir]
  )

export const RecurringTasksTable: FC<RecurringTasksTableProps> = ({
  tableSize,
  isLoading,
  data,
  paginationOptions,
  openDetailsMode,
  viewMode
}) => {
  const config = useConfig()
  const {t} = useTranslation()
  const columns = useMemo<ColumnsMap<ShiftEvent>>(
    () => generateColumnsMap(t, config.timezone, config.plantNow()),
    [t, config]
  )

  const {sortedList, onSortClick, orderByKey, sortDir} = useSorting<
    ShiftEvent,
    EventTableColumnKey
  >({
    initialSortDir: false,
    initialOrderByKey: 'type',
    data: (data?.taskSchedules as ShiftEvent[]) || [],
    sort: sortEvents
  })
  const sortDirection = !sortDir ? undefined : sortDir

  const selectedItemId =
    viewMode.mode === 'detailsMode' || viewMode.mode === 'editMode' ? viewMode.itemId : undefined

  return (
    <Box
      data-test-id="events-table"
      display="flex"
      flexDirection="column"
      flex={1}
      overflow="hidden"
      justifyContent="space-between"
    >
      <DataTable<ShiftEvent>
        columns={columns[tableSize]}
        data={sortedList || []}
        onSort={(event, key: EventTableColumnKey) => onSortClick(key)}
        sortedBy={orderByKey}
        sortDirection={sortDirection}
        rowSx={(item) => rowSx(item, selectedItemId === item.id)}
        emptyMessage={(!isLoading && t('eventsTable.noData')) ?? ''}
        onRowClick={(keyEvent: React.MouseEvent, clickedItem: ShiftEvent) =>
          openDetailsMode(clickedItem)
        }
        paginationOptions={paginationOptions}
        loading={isLoading}
        data-test-id="events-table-container"
      />
    </Box>
  )
}
