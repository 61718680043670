/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {decodeAccessToken} from '@hconnect/apiclient'
import type MockAdapter from 'axios-mock-adapter/types'
import moment from 'moment-timezone'

import {Comment, ShiftCommentCreate, ShiftEventCommentCreate} from '../../types/shiftHandover.types'
import {TimeRange} from '../../types/timeAndDate.types'
import {mockStore} from '../mockStoreSingleton'

function getRelevantComments(timeRange: TimeRange, allComments: Comment[]): Comment[] {
  const {startDate, endDate} = timeRange

  const start = new Date(startDate)
  const end = new Date(endDate)

  const relevantComments: Comment[] = []
  for (const comment of allComments) {
    const commentDate = new Date(comment.createDate)

    if (start <= commentDate && commentDate <= end) {
      relevantComments.push(comment)
    }
  }
  return relevantComments
}

export const enableCommentsMock = (apiMockAdapter: MockAdapter) => {
  apiMockAdapter.onGet(/\/shifthandover\/\w+\/events\/.+\/comments/).reply((config) => {
    const eventId = config.url!.split('/')[4]
    const mockState = mockStore.scenario()
    const comments = mockState.comments[eventId] ?? []

    return [200, comments]
  })

  apiMockAdapter.onPost(/\/shifthandover\/\w+\/events\/.+\/comments/).reply((config) => {
    const eventId = parseInt(config.url!.split('/')[4])
    const mockState = mockStore.scenario()
    const requestDto = JSON.parse(config.data as string) as ShiftEventCommentCreate

    if (!eventId) {
      return [404, 'Request can not be proceeded, supplied event id not found']
    }

    const previousComments: Comment[] = mockState.comments[eventId] ?? []
    const newId = Math.floor(Math.random() * 1000000).toString()

    const user: string = (config.headers?.['Authorization'] as string) ?? ''
    const token: {given_name: string; user_id: string} = decodeAccessToken<{
      given_name: string
      user_id: string
    }>(user)
    const now = mockState.currentTimeUtc ?? moment.utc().toISOString()

    const comment: Comment = {
      ...requestDto,
      id: newId,
      creator: token.given_name,
      creatorId: token.user_id,
      createDate: now,
      isEditable: false
    }

    mockState.comments = {
      ...mockState.comments,
      [eventId]: [...previousComments, comment]
    }

    mockStore.setScenarioData(mockState)

    return [201, comment]
  })

  apiMockAdapter.onPost(/\/shifthandover\/\w+\/shiftcomments/).reply((config) => {
    const mockState = mockStore.scenario()
    const allComments = mockState.shiftComments ?? []
    const comments = getRelevantComments(
      JSON.parse(config.data as string) as TimeRange,
      allComments
    )
    return [200, comments]
  })

  apiMockAdapter.onPut(/\/shifthandover\/\w+\/shiftcomments/).reply((config) => {
    const mockState = mockStore.scenario()
    const requestDto: ShiftCommentCreate = JSON.parse(config.data as string)

    const {description, submittedFor} = requestDto

    const newId = Math.floor(Math.random() * 1000000).toString()

    const user: string = (config.headers?.['Authorization'] as string) ?? ''
    const token: {given_name: string; user_id: string} = decodeAccessToken<{
      given_name: string
      user_id: string
    }>(user)

    const comment: Comment = {
      description,
      id: newId,
      creator: token.given_name,
      creatorId: token.user_id,
      createDate: submittedFor,
      isEditable: false
    }
    mockState.shiftComments.push(comment)
    mockStore.setScenarioData(mockState)

    return [201, comment]
  })
}
