import {AxiosError} from 'axios'
import {useQuery, UseQueryOptions} from 'react-query'

import {FilterOptions} from '../../common/utils/filterParameter'
import {useConfig} from '../useConfig'

import {TaskSchedulesResult} from './types'
import {useApi} from './useApi'

const QUERY_KEY = 'repetitive-tasks'

export const useRepetitiveTasks = (
  filters: FilterOptions,
  itemsPerPage = 50,
  pageNumber = 0,
  options?: UseQueryOptions<TaskSchedulesResult, AxiosError>
) => {
  const {axiosInstance} = useApi()
  const {plantId} = useConfig()
  const params = {
    itemsPerPage,
    pageNumber,
    ...filters
  }
  const path = `/shifthandover/${plantId}/search/taskschedules`

  return useQuery<TaskSchedulesResult, AxiosError>(
    [QUERY_KEY, filters, params, plantId],
    async () => {
      const response = await axiosInstance.post<TaskSchedulesResult>(path, params)
      return response.data
    },
    options
  )
}
