import {Theme, useMediaQuery} from '@mui/material'
import {FC} from 'react'

import {Page} from '../../common/utils/filterParameter'
import {useWorkOrders} from '../../hooks/api/workOrders/useWorkOrders'
import {usePagedData, MOBILE_ELEMENTS_PER_PAGE} from '../../hooks/usePagination'
import {EventsTab} from '../../types/shiftHandover.types'
import {WorkOrder} from '../../types/workOrders.types'

import {WorkOrdersListView} from './WorkOrdersListView'

type WorkOrdersContainerProps = {
  activeTab: EventsTab
  setActiveTab: (tab: EventsTab) => void
}

export const WorkOrdersContainer: FC<WorkOrdersContainerProps> = ({activeTab, setActiveTab}) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))

  const {
    data,
    isLoading,
    filterOptions,
    setFilters,
    changePage,
    setItemsPerPage,
    paginationOptions,
    shouldLoadNextPage
  } = usePagedData<WorkOrder>({
    fetchData: useWorkOrders,
    pageType: Page.WorkOrders
  })

  return (
    <WorkOrdersListView
      activeTab={activeTab}
      setActiveTab={setActiveTab}
      activeFilter={filterOptions}
      events={data?.items}
      isLoading={isLoading}
      onFilterChange={(nextFilterOptions) => {
        changePage(0)
        if (isMobile) {
          setItemsPerPage(MOBILE_ELEMENTS_PER_PAGE)
        }
        setFilters(nextFilterOptions)
      }}
      paginationOptions={isMobile ? undefined : paginationOptions}
      showBottomProgress={isMobile && shouldLoadNextPage}
    />
  )
}
