import {FlexPage} from '@hconnect/common/components/FlexPage'
import {
  noSideCard,
  sideCardSize,
  tableWithOutSideCard,
  tableWithSideCard,
  withSideCard
} from '@hconnect/common/consts'
import {useWidth} from '@hconnect/common/hooks/useWidth'
import {CardBox, DataTable, type PaginationOptions} from '@hconnect/uikit/src/lib2'
import {Grid, Theme} from '@mui/material'
import {TFunction} from 'i18next'
import React, {useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {defaultDesktop, FilterOptions} from '../../common/utils/filterParameter'
import {ShiftEventsTabToggle} from '../../components/common/ShiftEventsTabToggle'
import {useQueryParamValue} from '../../hooks/useQueryParamValue'
import {EventsFilterBar} from '../../layouts/FilterBar'
import {getActionContentSXWithFilter} from '../../styles/common'
import {eventRowSx} from '../../styles/eventTable'
import {EventsTab, SortOrder} from '../../types/shiftHandover.types'
import {ColumnsMap} from '../../types/table.types'
import {SideCardComponentProps} from '../../types/workOrders.types'
import {getSortDirection, getSortOrder} from '../../utils'

type EventsListViewProps<T, K, L> = {
  activeTab: EventsTab
  setActiveTab: (tab: EventsTab) => void
  activeFilter: FilterOptions
  events?: T[]
  isLoading?: boolean
  showBottomProgress?: boolean
  onFilterChange: (next: FilterOptions) => void
  paginationOptions?: PaginationOptions
  generateColumnsMap: (t: TFunction, freeSearchText?: string) => ColumnsMap<T>
  SideCardComponent: SideCardComponentProps<T>
  urlParamId: string
  filterOptions: K[]
  sortedBy?: L
  sortDirection?: SortOrder
  onSort: (columnName: string, sortOrder?: SortOrder) => void
}

export const EventsListView = <T extends {id: string}, K extends string, L extends string>({
  activeTab,
  setActiveTab,
  activeFilter,
  events,
  isLoading,
  onFilterChange,
  paginationOptions,
  generateColumnsMap,
  SideCardComponent,
  urlParamId,
  filterOptions,
  sortedBy,
  sortDirection,
  onSort
}: EventsListViewProps<T, K, L>) => {
  const breakPoint = useWidth()
  const {t} = useTranslation()
  const keys = useMemo(() => [urlParamId], [urlParamId])
  const [selectedItemId = '', setSelectedItemId] = useQueryParamValue(urlParamId, keys, {})
  const activeItemId = new URLSearchParams(selectedItemId).get(urlParamId) ?? undefined
  const selectedItem = useMemo(
    () => events?.find((item) => item.id === activeItemId),
    [activeItemId, events]
  )
  const [isMobileFilterOpen, openMobileFilter] = useState(false)
  const columns = generateColumnsMap(t, activeFilter.freeText)
  const tableSize = (activeItemId ? withSideCard : noSideCard).get(breakPoint) ?? 'S'
  const isSmall = breakPoint === 'xs' || breakPoint === 'sm'
  const isTableShown = !activeItemId || !isSmall
  const currentSortDirection = getSortDirection(sortDirection)

  return (
    <FlexPage
      appName="Cockpit"
      title={
        isMobileFilterOpen ? (
          t('shiftEvent.action.filter')
        ) : (
          <ShiftEventsTabToggle activeTab={activeTab} setActiveTab={setActiveTab} />
        )
      }
      sxActionContent={getActionContentSXWithFilter(isMobileFilterOpen)}
      headerActionContent={
        <Grid container alignItems="center">
          {isTableShown && (
            <Grid item xs={12}>
              <EventsFilterBar<K>
                activeSettings={activeFilter}
                isCollapsible={isSmall}
                options={filterOptions}
                onChange={onFilterChange}
                openMobileFilter={openMobileFilter}
                isMobileFilterOpen={isMobileFilterOpen}
                defaultFilters={defaultDesktop}
              />
            </Grid>
          )}
        </Grid>
      }
      useViewportHeight={!isSmall}
    >
      <Grid container flexGrow={1} overflow={'hidden'} spacing={2}>
        {isTableShown && (
          <Grid item height={'100%'} {...(activeItemId ? tableWithSideCard : tableWithOutSideCard)}>
            <CardBox
              sx={(theme: Theme) => ({
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                [theme.breakpoints.between('md', 'xl')]: {
                  zoom: 0.8
                }
              })}
            >
              <DataTable<T>
                columns={columns[tableSize]}
                data={events || []}
                rowSx={(item) => eventRowSx(activeItemId === item.id)}
                emptyMessage={(!isLoading && t('eventsTable.noData')) ?? ''}
                onRowClick={(keyEvent: React.MouseEvent, clickedItem: T) =>
                  setSelectedItemId(`${urlParamId}=${clickedItem.id}`)
                }
                paginationOptions={paginationOptions}
                loading={isLoading}
                data-test-id="events-table-container"
                sortedBy={sortedBy}
                sortDirection={currentSortDirection}
                onSort={(event, columnKey) => {
                  const sortOrder = getSortOrder(
                    sortedBy && currentSortDirection === 'asc' ? 'desc' : 'asc'
                  )
                  onSort(columnKey, sortOrder)
                }}
              />
            </CardBox>
          </Grid>
        )}

        {activeItemId && (
          <Grid
            item
            {...sideCardSize}
            sx={{
              height: '100%',
              overflow: 'hidden'
            }}
          >
            <SideCardComponent
              item={selectedItem}
              itemId={activeItemId}
              doClose={() => setSelectedItemId('')}
            />
          </Grid>
        )}
      </Grid>
    </FlexPage>
  )
}
