import {Extras} from '@hconnect/common/logging'
import {Config} from '@hconnect/common/types'
import {AxiosError, AxiosInstance} from 'axios'

import {FeatureFlagKey} from '../../types/shiftHandover.types'
import {getDefaultFeatureFlags} from '../utils/featureFlags'
import {HLog} from '../utils/logging'

export async function getConfig(
  axiosInstance: AxiosInstance,
  plantId?: string,
  language?: string
): Promise<Config<FeatureFlagKey> & {serverTimestamp: string}> {
  try {
    const plantIdParameter = plantId ?? 'UnknownId'

    const configRequest = await axiosInstance.get<Config<FeatureFlagKey>>(
      `/shifthandover/${plantIdParameter}/config`,
      {
        params: {
          language: language || 'en-US'
        }
      }
    )
    const serverTimestamp = configRequest.headers['Date']

    configRequest.data.featureFlags = getDefaultFeatureFlags(configRequest.data.featureFlags)

    return {...configRequest.data, serverTimestamp}
  } catch (error) {
    const err = error as AxiosError<Extras>
    HLog('cockpit')
      .extras(err.response?.data ?? {})
      .warning(`${err.code} ${err.config?.method?.toUpperCase()} ${err.config?.url}`)
    throw err
  }
}
